import LocationOnIcon from '@mui/icons-material/LocationOn'
import MailIcon from '@mui/icons-material/Mail'
import PhoneIcon from '@mui/icons-material/Phone'
import {
    Box,
    Button,
    ButtonBase,
    Card,
    CardContent,
    CardMedia,
    Container,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { useDirection } from 'core/services/googleMaps/useDirection'
import { useStaticMap } from 'core/services/mapbox/useStaticMap'
import { usePhoneNumber } from 'core/services/phone/usePhoneNumber'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { CardTitle } from './common/cardTitle'
import Section from './common/section'
import { Title } from './common/title'
import BusinessHours from './information/businessHours'
import SpecialClosing from './information/specialClosing'

const InformationCardContent = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
    },
})) as typeof CardContent

const InformationList = styled(List)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        '& .MuiListItem-root': {
            paddingLeft: theme.spacing(0.5),
            paddingRight: 0,
        },
        '& .MuiListItemIcon-root': {
            minWidth: 48,
        },
    },
})) as typeof List

const InformationContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        maxWidth: 400,
    },
})) as typeof Container

interface Props {
    establishment: EstablishmentModel
    color: 'primary' | 'secondary'
}

const InformationSection = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            establishment: {
                name,
                completeAddress,
                phoneNumber,
                email,
                businessHours,
                specialClosing,
                location,
                websiteSections,
            },
            color,
        },
        ref,
    ) => {
        const { t } = useTranslation()

        const id = 'information'
        const title = t('menu.information')
        const section = websiteSections?.find((section) => section.section === id)

        const theme = useTheme()
        const isMobileScreen = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true })
        const { displayPhone, validPhone, getPhoneNumber, getInternationalPhone } = usePhoneNumber()
        const { getMap } = useStaticMap()
        const { getDirection, getMapLink } = useDirection()

        const spacing = isMobileScreen ? 2 : 4

        const displayAddress = () => {
            return (
                <>
                    <Typography>{name}</Typography>
                    <Typography itemProp="streetAddress">
                        {completeAddress.street_1}
                        <br /> {completeAddress.street_2}
                    </Typography>
                    <Typography>
                        <span itemProp="postalCode">{completeAddress.zip_code}</span>{' '}
                        <span itemProp="addressLocality">{completeAddress.city}</span>
                    </Typography>
                    <meta itemProp="addressCountry" content={completeAddress.country} />
                </>
            )
        }
        const address = `${completeAddress.street_1} ${completeAddress.street_2}, ${completeAddress.zip_code}, ${completeAddress.city}`
        const cardColor = 'secondary'
        return (
            <Section
                ref={ref}
                id={id}
                color={color}
                title={title}
                backgroundImage={section?.backgroundImage ?? undefined}
                backgroundColor={section?.backgroundColor ?? undefined}
            >
                <InformationContainer maxWidth="md">
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            zIndex: 3,
                        }}
                        spacing={spacing}
                    >
                        <Grid item xs={12}>
                            <Title
                                component="h2"
                                color={color}
                                sx={{
                                    color: section?.textColor,
                                }}
                            >
                                {title}
                            </Title>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <InformationCardContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <CardTitle component="h3" color={cardColor}>
                                                <Trans i18nKey="establishment.location" />
                                            </CardTitle>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={spacing}>
                                                {location.lat && location.lng && (
                                                    <Grid item sm={7} xs={12}>
                                                        <span
                                                            itemProp="geo"
                                                            itemScope
                                                            itemType="https://schema.org/GeoCoordinates"
                                                        >
                                                            <meta
                                                                itemProp="latitude"
                                                                content={location.lat.toString()}
                                                            />
                                                            <meta
                                                                itemProp="longitude"
                                                                content={location.lng.toString()}
                                                            />
                                                        </span>
                                                        <ButtonBase
                                                            href={getMapLink(location.lat, location.lng)}
                                                            sx={{ height: '100%', width: '100%' }}
                                                            target="_blank"
                                                            rel="noopener"
                                                        >
                                                            <CardMedia
                                                                itemProp="hasMap"
                                                                component="img"
                                                                src={getMap(location.lat, location.lng)}
                                                                height="100%"
                                                            />
                                                        </ButtonBase>
                                                    </Grid>
                                                )}
                                                <Grid item sm={location.lat && location.lng ? 5 : 12} xs={12}>
                                                    <InformationList color={cardColor}>
                                                        <ListItem alignItems="flex-start">
                                                            <ListItemIcon>
                                                                <LocationOnIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                itemProp="address"
                                                                itemScope
                                                                itemType="https://schema.org/PostalAddress"
                                                                primary={displayAddress()}
                                                            />
                                                        </ListItem>
                                                        {validPhone(phoneNumber) && (
                                                            <ListItem
                                                                component={Link}
                                                                itemProp="telephone"
                                                                {...{
                                                                    content:
                                                                        getInternationalPhone(phoneNumber),
                                                                }}
                                                                button
                                                                href={getPhoneNumber(phoneNumber) ?? ''}
                                                                sx={{
                                                                    'textDecorationLine': 'underline',
                                                                    '&:hover': {
                                                                        textDecorationLine: 'none',
                                                                    },
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    <PhoneIcon />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={displayPhone(phoneNumber)}
                                                                />
                                                            </ListItem>
                                                        )}
                                                        {email && (
                                                            <ListItem
                                                                component={Link}
                                                                button
                                                                itemProp="email"
                                                                {...{ content: email }}
                                                                href={'mailto:' + email}
                                                                sx={{
                                                                    'textDecorationLine': 'underline',
                                                                    '&:hover': {
                                                                        textDecorationLine: 'none',
                                                                    },
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    <MailIcon />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Trans i18nKey="establishment.contact" />
                                                                    }
                                                                />
                                                            </ListItem>
                                                        )}
                                                    </InformationList>
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            display: 'inline-flex',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            href={getDirection(address)}
                                                            target="_blank"
                                                            rel="noopener"
                                                            sx={{
                                                                width: 160,
                                                                maxWidth: '100%',
                                                                marginTop: 4,
                                                            }}
                                                        >
                                                            <Trans i18nKey="establishment.direction" />
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </InformationCardContent>
                                <Divider />
                                <InformationCardContent>
                                    <Grid container spacing={spacing}>
                                        <Grid item sm={6} xs={12}>
                                            <BusinessHours
                                                businessHours={businessHours}
                                                specialClosing={specialClosing}
                                                color={cardColor}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <SpecialClosing
                                                specialClosings={specialClosing}
                                                color={cardColor}
                                            />
                                        </Grid>
                                    </Grid>
                                </InformationCardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </InformationContainer>
            </Section>
        )
    },
)

export default InformationSection
