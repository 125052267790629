import { CssBaseline, ThemeProvider } from '@mui/material'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { createEstablishmentTheme } from 'core/style/theme'
import * as React from 'react'

interface WithMuiThemeProvider {
    establishment?: EstablishmentModel
}

const withMuiTheme = <T extends WithMuiThemeProvider>(ComposedComponent: React.ComponentType<T>) => {
    const WithMuiTheme: React.FC<T> = (props) => (
        <React.Fragment>
            <CssBaseline />
            <ThemeProvider theme={createEstablishmentTheme(props.establishment)}>
                <ComposedComponent {...props} />
            </ThemeProvider>
        </React.Fragment>
    )
    return WithMuiTheme
}

export default withMuiTheme
