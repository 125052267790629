import { styled, Typography } from '@mui/material'

export const Title = styled(Typography)(({ theme, color }) => ({
    color: color === 'primary' ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText,
    fontSize: '2rem',
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.3rem',
        paddingBottom: theme.spacing(1),
    },
})) as typeof Typography
