const baseUrl = 'https://api.mapbox.com/styles/v1/mapbox/light-v10/static/'
export const useStaticMap = () => {
    const getMap = (latitude: number, longitude: number) => {
        return (
            baseUrl +
            'pin-s-marker+F38355(:lng,:lat)/:lng,:lat,16/400x300?access_token=:accessToken'
                .replace(':lng', longitude.toString())
                .replace(':lat', latitude.toString())
                .replace(':lng', longitude.toString())
                .replace(':lat', latitude.toString())
                .replace(':accessToken', process.env.GATSBY_MAPBOX_ACCESS_TOKEN ?? '')
        )
    }

    return {
        getMap,
    }
}
