import { Grid } from '@mui/material'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { Form, FormSpy } from 'react-final-form'
import TextField from '../common/forms/textField'
import EmailField from '../common/forms/emailField'
import PhoneField from 'components/common/forms/phoneField'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { validateAWSEmail } from 'appsync-scalar-utils'
import SelectField, { SelectValue } from '../common/forms/selectField'
import DateField from '../common/forms/dateField'
import moment, { Moment } from 'moment'
import TimeField from '../common/forms/timeField'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: BookingFormState) => void
    children: (content: React.ReactNode, actions: Array<SubmitButtonProps<any>>) => React.ReactNode
    inProgress: boolean
    initialValues: BookingFormState
    shouldDisableDate?: (day: Moment) => boolean
    shouldDisableTime?: (
        date: Moment,
        timeValue: number,
        clockType: 'hours' | 'minutes' | 'seconds',
        hour?: number,
    ) => boolean
}

export interface BookingFormState {
    givenName: string
    familyName: string
    email: string
    phone: string
    comment: string
    date: Moment
    time: Moment
    guests: number
}

interface BookingFormError {
    givenName?: string
    familyName?: string
    email?: string
    phone?: string
    comment?: string
    date?: string
    time?: string
    guests?: string
}

const BookingForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    inProgress,
    initialValues,
    shouldDisableDate,
    shouldDisableTime,
}) => {
    const { t } = useTranslation()
    const validate = (values: BookingFormState) => {
        const errors: BookingFormError = {}

        if (!values.givenName) {
            errors.givenName = t('formValidation.givenName')
        } else if (values.givenName.length < 2) {
            errors.givenName = t('formValidation.givenNameLength')
        }

        if (!values.familyName) {
            errors.familyName = t('formValidation.familyName')
        } else if (values.familyName.length < 2) {
            errors.familyName = t('formValidation.familyNameLength')
        }

        if (!values.email) {
            errors.email = t('formValidation.email')
        } else if (!validateAWSEmail(values.email)) {
            errors.email = t('formValidation.emailValid')
        }

        if (!values.phone) {
            errors.phone = t('formValidation.phone')
        } else if (values.phone !== '') {
            const phone = parsePhoneNumberFromString(values.phone, 'FR')
            if (phone === undefined || !phone.isValid()) {
                errors.phone = t('formValidation.phone')
            }
        }
        return errors
    }

    const guestsPossibilities: Array<SelectValue> = []
    for (let i = 1; i <= 30; i++) {
        guestsPossibilities.push({
            key: 'guestsPossibilities' + i,
            label: i === 1 ? t('form.guestsNumberOne') : t('form.guestsNumber', { number: i }),
            value: i,
        })
    }
    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    {children(
                        <>
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        margin="none"
                                        name="givenName"
                                        label={t('form.givenName')}
                                        autoComplete="given-name"
                                        required
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        margin="none"
                                        name="familyName"
                                        label={t('form.familyName')}
                                        autoComplete="family-name"
                                        required
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <EmailField margin="none" label={t('form.email')} name="email" required />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <PhoneField margin="none" label={t('form.phone')} name="phone" required />
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <SelectField
                                        margin="none"
                                        name="guests"
                                        label={t('form.guests')}
                                        possibilities={guestsPossibilities}
                                        required
                                    />
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <DateField
                                        minDate={moment()}
                                        margin="none"
                                        name="date"
                                        label={t('form.date')}
                                        shouldDisableDate={shouldDisableDate}
                                        required
                                    />
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <FormSpy subscription={{ values: true }}>
                                        {(props) => (
                                            <TimeField
                                                minTime={
                                                    moment(props.values.date).isSame(moment(), 'day')
                                                        ? moment().add(1, 'hour')
                                                        : undefined
                                                }
                                                margin="none"
                                                name="time"
                                                label={t('form.time')}
                                                shouldDisableTime={(timeValue, clockType, hour) => {
                                                    if (shouldDisableTime && props.values.date) {
                                                        return shouldDisableTime(
                                                            props.values.date,
                                                            timeValue,
                                                            clockType,
                                                            hour,
                                                        )
                                                    }
                                                    return false
                                                }}
                                                minutesStep={5}
                                                required
                                            />
                                        )}
                                    </FormSpy>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="none"
                                        name="comment"
                                        multiline
                                        rows={4}
                                        label={t('form.comment')}
                                    />
                                </Grid>
                            </Grid>
                        </>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                children: <Trans i18nKey="actions.book" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}

export default BookingForm
