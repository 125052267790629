import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import {
    ButtonBase,
    CardMedia,
    Container,
    Grid,
    IconButton,
    styled,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { useImgix } from 'core/services/imgix/useImgix'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import Section from './common/section'
import { Title } from './common/title'

const PhotosContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        maxWidth: 400,
    },
})) as typeof Container

interface Props {
    establishment: EstablishmentModel
    color: 'primary' | 'secondary'
    onZoom: (image: string) => void
}

const PhotosSection = React.forwardRef<HTMLDivElement, Props>(
    ({ establishment: { pictures, websiteSections }, color, onZoom }, ref) => {
        const { t } = useTranslation()

        const id = 'photos'
        const title = t('menu.photos')
        const section = websiteSections?.find((section) => section.section === id)

        const theme = useTheme()
        const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true })
        const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })

        const { getImage } = useImgix()
        const columns = isMobileScreen ? 2 : 4
        const spacing = isMobileScreen ? 2 : 4
        const getImageSource = (picture?: string, dpr?: number) => {
            const image = picture ?? process.env.GATSBY_ASSETS_URL + '/media/establishment/default.jpg'
            let width = 1440
            if (isLargeScreen) {
                width = 1536
            } else {
                if (typeof window !== 'undefined' && document) {
                    width =
                        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
                }
            }
            const targetWidth = Math.round((width - spacing * 8 * columns) / columns)
            const targetHeight = Math.round((targetWidth * 4) / 3)
            return {
                width: targetWidth,
                height: targetHeight,
                image: getImage(image, { w: targetWidth, h: targetHeight, fit: 'crop', dpr }),
            }
        }
        return (
            <Section
                ref={ref}
                id={id}
                color={color}
                title={title}
                backgroundImage={section?.backgroundImage ?? undefined}
                backgroundColor={section?.backgroundColor ?? undefined}
            >
                <PhotosContainer maxWidth="xl">
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            zIndex: 3,
                        }}
                        spacing={spacing}
                    >
                        <Grid item xs={12}>
                            <Title
                                component="h2"
                                color={color}
                                sx={{
                                    color: section?.textColor,
                                }}
                            >
                                {title}
                            </Title>
                        </Grid>
                        {pictures.map(({ url }, index) => {
                            const { image } = getImageSource(url)
                            return (
                                <Grid
                                    item
                                    xs={6}
                                    sm={3}
                                    key={'pictures' + index}
                                    sx={{
                                        position: 'relative',
                                        display: 'inline-flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ButtonBase
                                        onClick={() => onZoom(url)}
                                        itemProp="photo"
                                        itemScope
                                        itemType="https://schema.org/ImageObject"
                                        sx={{ width: '100%', height: '100%' }}
                                    >
                                        <CardMedia
                                            itemProp="contentUrl"
                                            {...{ content: getImage(url) }}
                                            component={'img'}
                                            src={image}
                                            srcSet={
                                                getImageSource(url, 1).image +
                                                ' 1x, ' +
                                                getImageSource(url, 2).image +
                                                ' 2x, ' +
                                                getImageSource(url, 3).image +
                                                ' 3x'
                                            }
                                        />
                                        <IconButton
                                            component={'div'}
                                            onClick={() => onZoom(url)}
                                            sx={{
                                                position: 'absolute',
                                                bottom: 8,
                                                right: 8,
                                                bgcolor: 'primary.main',
                                                borderColor: 'secondary.main',
                                                borderWidth: 1,
                                                borderStyle: 'solid',
                                            }}
                                            color="primary"
                                            size={isMobileScreen ? 'medium' : 'large'}
                                        >
                                            <ZoomOutMapIcon color="secondary" fontSize="inherit" />
                                        </IconButton>
                                    </ButtonBase>
                                </Grid>
                            )
                        })}
                    </Grid>
                </PhotosContainer>
            </Section>
        )
    },
)

export default PhotosSection
