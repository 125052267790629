import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import React from 'react'

interface Props {
    onClose: () => void
}

const CloseButton: React.FC<Props> = ({ onClose }) => {
    return (
        <IconButton
            onClick={onClose}
            sx={{
                color: '#7f7f7f',
                bgcolor: 'transparent',
            }}
        >
            <CloseIcon sx={{ fontSize: '1.6rem' }} />
        </IconButton>
    )
}

export default CloseButton
