import React, { useRef } from 'react'
import { NewsModel } from '../../core/models/newsModel'
import ImageCard from './imageCard'
import { Box, Grid, Hidden, IconButton, styled, useMediaQuery, useTheme } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Lazy, Navigation, Zoom } from 'swiper'
import { useImgix } from '../../core/services/imgix/useImgix'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import clsx from 'clsx'

interface Props {
    news: NewsModel
    onZoom: (image: string) => void
    getImageSource: (
        picture?: string | null,
        dpr?: number,
    ) => { width: number; height: number; image: string }
    color: 'primary' | 'secondary'
}

const NavigationButton = styled(Box)(({ theme, color }) => ({
    'color':
        (color === 'primary' ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText) +
        ' !important',
    '&:after': {
        borderColor:
            (color === 'primary'
                ? theme.palette.primary.contrastText
                : theme.palette.secondary.contrastText) + ' !important',
        textShadow:
            '0px 0px 2px ' +
            (color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main),
    },
})) as typeof Box

const ImageContainer: React.FC<Props> = ({ news, onZoom, getImageSource, color }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })
    const { getImage } = useImgix()

    const navigationPrevRef = useRef<HTMLDivElement>(null)
    const navigationNextRef = useRef<HTMLDivElement>(null)

    if (news.images === undefined || news.images === null) {
        return <></>
    } else {
        // Single image
        if (news.images?.length === 1) {
            return (
                <ImageCard
                    imageUrl={news.images[0].picture}
                    createdAt={news.createdAt}
                    onZoom={onZoom}
                    getImageSource={getImageSource}
                />
            )
        } else {
            return (
                <Grid container justifyContent="center" alignItems="center" spacing={0.5}>
                    <Swiper
                        height={300}
                        onInit={(swiper) => {
                            const navigationPrevElement = navigationPrevRef.current
                            const navigationNextElement = navigationNextRef.current
                            const navigation = swiper.params.navigation
                            if (navigationPrevElement && navigationNextElement && navigation) {
                                swiper.navigation.destroy()
                                // @ts-ignore
                                navigation.prevEl = navigationPrevElement
                                // @ts-ignore
                                navigation.nextEl = navigationNextElement
                                swiper.navigation.init()
                                swiper.navigation.update()
                            }
                        }}
                        modules={[Navigation, Zoom, Lazy, Keyboard]}
                        centerInsufficientSlides
                        slidesPerView={1}
                        lazy
                        zoom={isMobileScreen}
                        keyboard
                        pagination={{
                            clickable: true,
                            renderBullet: (_, className) => {
                                return (
                                    '<span class="' +
                                    className +
                                    '" style="background-color: ' +
                                    (color === 'primary' ? '#fff' : '#000') +
                                    '"></span>'
                                )
                            },
                        }}
                        spaceBetween={24}
                    >
                        {/* Nav btn */}
                        <Hidden implementation="css">
                            <NavigationButton
                                color={color}
                                component={'div'}
                                ref={navigationPrevRef}
                                className="swiper-button-prev"
                            />
                            <NavigationButton
                                color={color}
                                component={'div'}
                                ref={navigationNextRef}
                                className="swiper-button-next"
                            />
                        </Hidden>
                        {news.images.map(({ picture }, index) => {
                            const { image, height, width } = getImageSource(picture)
                            return (
                                <SwiperSlide
                                    zoom={isMobileScreen}
                                    key={'event' + index}
                                    style={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: height,
                                        width: isMobileScreen ? width : 'inherit',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <>
                                        <div style={{ position: 'relative' }}>
                                            <img
                                                key={'newsImage' + index}
                                                itemProp="image"
                                                data-src={image}
                                                data-srcset={
                                                    getImageSource(image, 1).image +
                                                    ' 1x, ' +
                                                    getImageSource(image, 2).image +
                                                    ' 2x, ' +
                                                    getImageSource(image, 3).image +
                                                    ' 3x'
                                                }
                                                {...{ content: getImage(picture) }}
                                                className="swiper-lazy"
                                                height="100%"
                                                width="100%"
                                                alt={t('menu.news')}
                                            />
                                            <IconButton
                                                key={'menuImageZoom' + index}
                                                onClick={() => onZoom(picture)}
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: 8,
                                                    right: 8,
                                                    bgcolor: 'primary.main',
                                                    borderColor: 'secondary.main',
                                                    borderWidth: 1,
                                                    borderStyle: 'solid',
                                                    display: { xl: 'inline-flex', md: 'none' },
                                                }}
                                                color="primary"
                                                size={isMobileScreen ? 'medium' : 'large'}
                                            >
                                                <ZoomOutMapIcon color="secondary" fontSize="inherit" />
                                            </IconButton>
                                        </div>
                                        <div
                                            className={clsx('swiper-lazy-preloader', {
                                                'swiper-lazy-preloader-white': color === 'primary',
                                                'swiper-lazy-preloader-black': color === 'secondary',
                                            })}
                                        />
                                    </>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </Grid>
            )
        }
    }
}

export default ImageContainer
