import { AppBar, Toolbar, useScrollTrigger } from '@mui/material'
import { cumulativeOffset } from 'components/helpers/offset'
import withMuiTheme from 'containers/hoc/withMuiTheme'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { usePhoneNumber } from 'core/services/phone/usePhoneNumber'
import { useWebsite } from 'core/services/website/useWebsite'
import { Trans } from 'gatsby-plugin-react-i18next'
import React, { memo, useEffect } from 'react'
import MenuItem from './header/menuItem'

interface Props {
    establishment: EstablishmentModel
}

const Header: React.FC<Props> = ({ establishment }) => {
    const { validPhone } = usePhoneNumber()
    const { isValidWebsite } = useWebsite()

    const id = 'title'
    const section = establishment.websiteSections?.find((section) => section.section === id)

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 64,
    })
    const targets: Array<{
        anchorStart: string
        text: string | React.ReactNode
    }> = []
    if (establishment.menuBoard) {
        targets.push({
            anchorStart: '#slate',
            text: <Trans i18nKey="menu.slate" />,
        })
    }
    if (establishment.menu && establishment.menu.length > 0) {
        targets.push({
            anchorStart: '#menu',
            text: <Trans i18nKey="menu.menu" />,
        })
    }
    if (establishment.newsList && establishment.newsList.length > 0) {
        targets.push({
            anchorStart: '#news',
            text: <Trans i18nKey="menu.news" />,
        })
    }
    targets.push({
        anchorStart: '#about',
        text: <Trans i18nKey="menu.about" />,
    })
    if (
        validPhone(establishment.phoneNumber) ||
        establishment.email ||
        isValidWebsite(establishment.website) ||
        establishment.delivery ||
        establishment.booking
    ) {
        targets.push({
            anchorStart: '#links',
            text: <Trans i18nKey="menu.links" />,
        })
    }
    if (
        establishment.orderModule &&
        validPhone(establishment.phoneNumber) &&
        establishment.email &&
        establishment.businessHours &&
        !(
            establishment.businessHours?.sunday?.length === 0 &&
            establishment.businessHours?.monday?.length === 0 &&
            establishment.businessHours?.tuesday?.length === 0 &&
            establishment.businessHours?.wednesday?.length === 0 &&
            establishment.businessHours?.thursday?.length === 0 &&
            establishment.businessHours?.friday?.length === 0 &&
            establishment.businessHours?.saturday?.length === 0
        )
    ) {
        targets.push({
            anchorStart: '#order',
            text: undefined,
        })
    }
    if (
        establishment.bookingModule &&
        validPhone(establishment.phoneNumber) &&
        establishment.email &&
        establishment.businessHours &&
        !(
            establishment.businessHours?.sunday?.length === 0 &&
            establishment.businessHours?.monday?.length === 0 &&
            establishment.businessHours?.tuesday?.length === 0 &&
            establishment.businessHours?.wednesday?.length === 0 &&
            establishment.businessHours?.thursday?.length === 0 &&
            establishment.businessHours?.friday?.length === 0 &&
            establishment.businessHours?.saturday?.length === 0
        )
    ) {
        targets.push({
            anchorStart: '#book',
            text: undefined,
        })
    }
    if (establishment.pictures && establishment.pictures.length > 0) {
        targets.push({
            anchorStart: '#photos',
            text: <Trans i18nKey="menu.photos" />,
        })
    }
    targets.push({
        anchorStart: '#information',
        text: <Trans i18nKey="menu.information" />,
    })
    targets.push({
        anchorStart: '#reviews',
        text: <Trans i18nKey="menu.reviews" />,
    })
    const elementStart = document.querySelector(targets[0].anchorStart) as HTMLElement | null
    const thresholdStart = cumulativeOffset(elementStart ?? undefined).top ?? 0
    const triggerFirst = useScrollTrigger({
        disableHysteresis: true,
        threshold: thresholdStart - 8,
    })
    useEffect(() => {
        if (!triggerFirst && window.location.hash) {
            window.history.pushState({}, '', window.location.origin + window.location.pathname)
        }
    }, [triggerFirst])
    const displayMenuItems = () => {
        return targets
            .filter((target) => target.text !== undefined)
            .map((target, index) => (
                <MenuItem
                    key={'MenuItem' + index}
                    color={trigger ? 'primary' : 'secondary'}
                    anchorStart={target.anchorStart}
                    anchorEnd={targets[index + 1]?.anchorStart}
                    text={target.text}
                    sx={{
                        color: trigger ? undefined : section?.textColor,
                    }}
                />
            ))
    }
    return (
        <AppBar color={trigger ? 'secondary' : 'transparent'} elevation={trigger ? 4 : 0}>
            <Toolbar sx={{ justifyContent: 'center' }}>{displayMenuItems()}</Toolbar>
        </AppBar>
    )
}

export default withMuiTheme(memo(Header))
