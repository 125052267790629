export const useWebsite = () => {
    const baseUrl = process.env.GATSBY_BASE_URL ?? '####'
    const isValidWebsite = (website?: string) => {
        if (website) {
            if (website.startsWith(baseUrl)) {
                return false
            } else if (website.startsWith('#')) {
                return false
            }
            return true
        }
        return false
    }

    const isValidDeliveryBooking = (delivery: string | undefined, hasModule: boolean) => {
        if (delivery) {
            if (delivery.startsWith(baseUrl)) {
                return hasModule
            }
            return true
        }
        return false
    }

    const getWebsite = (booking: string | undefined, hasModule: boolean) => {
        if (booking) {
            if (booking.startsWith(baseUrl)) {
                if (hasModule) {
                    const url = new URL(booking)
                    return url.hash
                } else {
                    return ''
                }
            }
            return booking
        }
        return ''
    }

    return {
        isValidWebsite,
        isValidDeliveryBooking,
        getWebsite,
    }
}
