import { Link, styled, useScrollTrigger } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import { cumulativeOffset } from 'components/helpers/offset'
import scrollTo from 'gatsby-plugin-smoothscroll'
import React, { memo, useEffect } from 'react'

interface Props {
    color: 'primary' | 'secondary'
    anchorStart: string
    anchorEnd?: string
    text: React.ReactNode
    sx?: SxProps<Theme>
}

const MenuButton = styled(Link)(({ theme }) => ({
    margin: theme.spacing(0, 2),
})) as typeof Link

const MenuItem: React.FC<Props> = ({ color, anchorStart, anchorEnd, text, sx }) => {
    let thresholdStart = 0
    if (typeof window !== 'undefined') {
        const elementStart = document.querySelector(anchorStart) as HTMLElement | null
        thresholdStart = cumulativeOffset(elementStart ?? undefined).top ?? 0
    }
    const triggerStart = useScrollTrigger({
        disableHysteresis: true,
        threshold: thresholdStart > 0 ? thresholdStart - 8 : 0,
    })
    let withStop = false
    let thresholdStop = 0
    if (anchorEnd) {
        withStop = true
        if (typeof window !== 'undefined') {
            const elementStop = document.querySelector(anchorEnd) as HTMLElement | null
            thresholdStop = cumulativeOffset(elementStop ?? undefined).top ?? 0
        }
    }
    const triggerStop = useScrollTrigger({
        disableHysteresis: true,
        threshold: thresholdStop > 0 ? thresholdStop - 8 : 0,
    })
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (
                ((triggerStart && !withStop) || (triggerStart && !triggerStop)) &&
                window.location.hash !== anchorStart
            ) {
                window.history.replaceState({}, '', anchorStart)
            }
        }
    }, [anchorStart, triggerStart, triggerStop, withStop])
    return (
        <MenuButton
            href={anchorStart}
            color={color}
            onClick={(e) => {
                if (typeof window !== 'undefined') {
                    e.preventDefault()
                    window.history.pushState({}, '', anchorStart)
                    scrollTo(anchorStart)
                }
            }}
            underline={(triggerStart && !withStop) || (triggerStart && !triggerStop) ? 'always' : 'hover'}
            sx={sx}
        >
            {text}
        </MenuButton>
    )
}

export default memo(MenuItem)
