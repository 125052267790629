import React from 'react'
import { Box, Grid, Link, styled, Typography } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'
import { Trans } from 'gatsby-plugin-react-i18next'

export const FooterText = styled(Typography)(({ theme }) => ({
    fontSize: '0.8rem',
    textAlign: 'center',
    color: theme.palette.secondary.main,
})) as typeof Typography

export const FooterBar = styled(Box)(() => ({
    height: 48,
    position: 'relative',
})) as typeof Box

const Footer: React.FC = () => {
    return (
        <FooterBar>
            <StaticImage
                src={'../../assets/footer.jpg'}
                alt="SuggPro"
                layout={'constrained'}
                style={{ height: 48 }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    inset: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Grid container justifyContent="center" alignItems={'center'} spacing={4}>
                    <Grid item>
                        <FooterText>
                            <Link href="https://www.suggpro.com" color="secondary" target="_blank">
                                <Trans
                                    i18nKey="footer.copyright"
                                    components={[
                                        <StaticImage
                                            src={'../../assets/suggpro.png'}
                                            alt="SuggPro"
                                            title="SuggPro"
                                            height={40}
                                            as={'span'}
                                            style={{ verticalAlign: 'middle' }}
                                        />,
                                    ]}
                                />
                            </Link>
                        </FooterText>
                    </Grid>
                </Grid>
            </Box>
        </FooterBar>
    )
}

export default Footer
