import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'

interface Props {
    bgcolor?: string
}

const Loader: React.FC<Props> = ({ bgcolor = 'primary.main' }) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: bgcolor,
            }}
        >
            <Box
                sx={{
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress size="4rem" color="secondary" />
                <Typography
                    sx={{
                        paddingTop: 4,
                        fontSize: '1.4rem',
                    }}
                    color="secondary"
                >
                    <Trans i18nKey="common.loading" />
                </Typography>
            </Box>
        </Box>
    )
}

export default Loader
