import React, { useState } from 'react'
import { Box, Dialog, useMediaQuery, useTheme } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Zoom } from 'swiper'
import { useImgix } from 'core/services/imgix/useImgix'
import CloseButton from './components/closeButton'
import Loader from '../common/loader'

interface Props {
    open: boolean
    image: string
    onClose: () => void
}

const ZoomDialog: React.FC<Props> = ({ open, image, onClose }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })
    const { getImage } = useImgix()
    const getImageSource = (picture?: string | null, dpr?: number) => {
        const image = picture ?? process.env.GATSBY_ASSETS_URL + '/media/establishment/default.jpg'
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        let height =
            (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) * 0.9
        if (fullScreen) {
            height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        }
        const targetWidth = Math.round(width)
        const targetHeight = Math.round(height)
        return {
            height: targetHeight,
            width: targetWidth,
            image: getImage(image, { w: targetWidth, h: targetHeight, fit: 'clip', dpr }),
        }
    }
    const { image: baseImage, height, width } = getImageSource(image)
    const [spinner, setSpinner] = useState(true)
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="xl"
                fullScreen={fullScreen}
                sx={{
                    '& .MuiDialog-paper': {
                        bgcolor: 'transparent',
                    },
                }}
            >
                {open && spinner && (
                    <div style={{ height, width }}>
                        <Loader bgcolor="transparent" />
                    </div>
                )}
                <Box
                    sx={{
                        bgcolor: 'primary.main',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CloseButton onClose={onClose} />
                    <Swiper
                        modules={[Zoom]}
                        slidesPerView={1}
                        zoom
                        height={fullScreen ? height : undefined}
                        width={fullScreen ? width : undefined}
                    >
                        <SwiperSlide
                            zoom
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                onLoad={() => setSpinner(false)}
                                src={baseImage}
                                srcSet={
                                    getImageSource(image, 1).image +
                                    ' 1x, ' +
                                    getImageSource(image, 2).image +
                                    ' 2x, ' +
                                    getImageSource(image, 3).image +
                                    ' 3x'
                                }
                                height="100%"
                                width="100%"
                            />
                        </SwiperSlide>
                    </Swiper>
                </Box>
            </Dialog>
        </>
    )
}

export default ZoomDialog
