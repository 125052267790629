import React from 'react'
import { NewsEventModel, NewsModel } from 'core/models/newsModel'
import { Card, CardActions, CardContent, IconButton, Link } from '@mui/material'
import moment from 'moment'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { UserText } from '../common/userText'
import { Anchorme } from 'react-anchorme'
import { Facebook, Google, Instagram, Twitter } from '@mui/icons-material'
import { NewsDate, NewsTitle } from '../newsSection'
import { EstablishmentModel } from 'core/models/establishmentModel'
import ImageContainer from '../../imageContainer'

interface EventsProps extends NewsModel {
    picture: string
    event: NewsEventModel
}

interface Props {
    event: EventsProps
    getImageSource: (
        picture?: string | null,
        dpr?: number,
    ) => { width: number; height: number; image: string }
    name: EstablishmentModel['name']
    completeAddress: EstablishmentModel['completeAddress']
    onZoom: (image: string) => void
    canonicalUrl: string
    color: 'primary' | 'secondary'
}

const EventCard: React.FC<Props> = ({
    event,
    getImageSource,
    name,
    completeAddress,
    onZoom,
    canonicalUrl,
    color,
}) => {
    const { t } = useTranslation()

    let startTime = moment(event.event.startTime).format('D MMM')
    let endTime = moment(event.endTime).format('D MMM')
    if (moment(event.event.startTime).isSame(moment(event.endTime), 'day')) {
        startTime = moment(event.event.startTime).format('D MMM, HH:mm')
        endTime = moment(event.endTime).format('HH:mm')
    }

    return (
        <Card itemProp="event" itemScope itemType="https://schema.org/Event">
            <CardContent>
                <span itemProp="location" itemScope itemType="https://schema.org/Place">
                    <meta itemProp="name" content={name} />
                    <span itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
                        <meta
                            itemProp="streetAddress"
                            content={completeAddress.street_1 + ' ' + completeAddress.street_2}
                        />
                        <meta itemProp="postalCode" content={completeAddress.zip_code} />
                        <meta itemProp="addressLocality" content={completeAddress.city} />
                        <meta itemProp="addressCountry" content={completeAddress.country} />
                    </span>
                </span>
                <span itemProp="organizer" itemScope itemType="https://schema.org/Restaurant">
                    <meta itemProp="name" content={name} />
                    <meta itemProp="url" content={canonicalUrl} />
                </span>
                <meta itemProp="eventAttendanceMode" content="OfflineEventAttendanceMode" />
                <meta itemProp="eventStatus" content="EventScheduled" />
                <NewsTitle component="h3" itemProp="name">
                    {event.event.title}
                </NewsTitle>
                <NewsDate>
                    <meta itemProp="startDate" content={moment(event.event.startTime).format()} />
                    <meta itemProp="endDate" content={moment(event.endTime).format()} />
                    <Trans
                        i18nKey="establishment.newsDate"
                        values={{
                            startTime,
                            endTime,
                        }}
                    />
                </NewsDate>
            </CardContent>
            {/* Images */}
            {event.images !== null && event.images !== undefined && event.images.length > 0 && (
                <ImageContainer news={event} onZoom={onZoom} getImageSource={getImageSource} color={color} />
            )}
            <CardContent>
                <UserText
                    itemProp="description"
                    {...{
                        content:
                            event.comment && event.comment !== ''
                                ? event.comment
                                : t('establishment.newsDescription', {
                                      title: event.event.title,
                                      startTime,
                                      endTime,
                                  }),
                    }}
                >
                    <Anchorme
                        linkComponent={(props) => (
                            // @ts-ignore
                            <Link {...props} />
                        )}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {event.comment ?? ''}
                    </Anchorme>
                </UserText>
                <CardActions sx={{ justifyContent: 'center' }}>
                    {event?.facebookPermalink && (
                        <IconButton href={event.facebookPermalink} target="_blank" rel="noopener">
                            <Facebook />
                        </IconButton>
                    )}
                    {event?.instagramPermalink && (
                        <IconButton href={event.instagramPermalink} target="_blank" rel="noopener">
                            <Instagram />
                        </IconButton>
                    )}
                    {event?.googleLocalPostPermalink && (
                        <IconButton href={event.googleLocalPostPermalink} target="_blank" rel="noopener">
                            <Google />
                        </IconButton>
                    )}
                    {event?.twitterPermalink && (
                        <IconButton href={event.twitterPermalink} target="_blank" rel="noopener">
                            <Twitter />
                        </IconButton>
                    )}
                </CardActions>
            </CardContent>
        </Card>
    )
}

export default EventCard
