import { useEffect, useState } from 'react'

let globalHubSpotFormInitialized = false
const useHubSpot = () => {
    const [hubSpotFormInitialized, setHubSpotFormInitialized] = useState(globalHubSpotFormInitialized)

    useEffect(() => {
        if (process.env.GATSBY_USE_HUBSPOT) {
            ;(function (d, s, id) {
                let js,
                    fjs = d.getElementsByTagName(s)[0]
                if (d.getElementById(id)) return
                js = d.createElement(s) as HTMLScriptElement
                js.id = id
                js.src = 'https://js.hs-scripts.com/' + process.env.GATSBY_HUBSPOT_PORTAL_ID + '.js'
                const a = fjs.parentNode
                if (a) {
                    a.insertBefore(js, fjs)
                }
            })(document, 'script', 'hubspot')
        }
        ;(function (d, s, id) {
            let js,
                fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) return
            js = d.createElement(s) as HTMLScriptElement
            js.id = id
            js.src = '//js.hsforms.net/forms/v2.js'
            js.addEventListener('load', function () {
                setHubSpotFormInitialized(true)
                globalHubSpotFormInitialized = true
            })
            const a = fjs.parentNode
            if (a) {
                a.insertBefore(js, fjs)
            }
        })(document, 'script', 'hubspot-shell')
    }, [])

    return hubSpotFormInitialized
}

export default useHubSpot
