import { styled, Typography } from '@mui/material'

export const CardAsset = styled(Typography)(({ theme, color }) => ({
    textAlign: 'center',
    fontSize: '0.8rem',
    marginTop: theme.spacing(1),
    color: theme.palette[color === 'primary' ? 'secondary' : 'primary'].main,
    [theme.breakpoints.only('xs')]: {
        fontSize: '.7rem',
    },
})) as typeof Typography
