import React from 'react'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
    onClose: () => void
}

const CloseButton: React.FC<Props> = ({ onClose }) => {
    return (
        <IconButton
            onClick={onClose}
            sx={{
                color: '#dde3f4',
                position: 'absolute',
                top: 24,
                right: 24,
                zIndex: 5,
                bgcolor: 'primary.main',
            }}
        >
            <CloseIcon sx={{ fontSize: '1.6rem' }} />
        </IconButton>
    )
}

export default CloseButton
