export const useDirection = () => {
    const getDirection = (address: string) => {
        return 'https://www.google.com/maps/dir/?api=1&destination=:address'.replace(':address', address)
    }
    const getMapLink = (latitude: number, longitude: number) => {
        return 'https://www.google.com/maps/@?api=1&map_action=map&center=:lat,:lng'
            .replace(':lng', longitude.toString())
            .replace(':lat', latitude.toString())
    }

    return {
        getDirection,
        getMapLink,
    }
}
