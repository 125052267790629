import React from 'react'
import { Field } from 'react-final-form'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'

export interface SelectValue {
    key: string
    label: React.ReactNode
    value: string | number | undefined
}

interface Props {
    name: string
    label: string
    possibilities: Array<SelectValue>
    required?: boolean
    autoComplete?: string
    variant?: 'standard' | 'outlined' | 'filled'
    fullWidth?: boolean
    margin?: 'dense' | 'normal' | 'none'
}

const SelectField: React.FC<Props> = ({
    name,
    label,
    possibilities,
    required,
    autoComplete,
    variant = 'outlined',
    fullWidth,
    margin = 'normal',
}) => {
    return (
        <Field name={name} type="select">
            {({ input: { name, onChange, value }, meta }) => {
                const hasError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <FormControl fullWidth margin={margin} required={required} error={hasError}>
                        <InputLabel>{label}</InputLabel>
                        <Select
                            id={name}
                            name={name}
                            label={label}
                            value={value}
                            variant={variant}
                            onChange={onChange}
                            required={required}
                            fullWidth={fullWidth}
                            autoComplete={autoComplete}
                        >
                            {possibilities.map((possibility) => (
                                <MenuItem value={possibility.value} key={possibility.key}>
                                    {possibility.label}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error={hasError}>{hasError ? meta.error : ''}</FormHelperText>
                    </FormControl>
                )
            }}
        </Field>
    )
}

export default SelectField
