import { styled, Typography } from '@mui/material'

export const CardTitle = styled(Typography)(({ theme, color }) => ({
    color: color === 'primary' ? theme.palette.secondary.main : theme.palette.primary.main,
    fontSize: '0.9rem',
    textTransform: 'uppercase',
    textAlign: 'left',
    fontWeight: 600,
    paddingBottom: theme.spacing(3),
})) as typeof Typography
