import {
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Link,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import SubmitButton from 'components/common/submitButton'
import OrderForm, { OrderFormState } from 'components/forms/orderForm'
import { mutations } from 'core/graphql/mutations'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { useOpeningHours } from 'core/services/openingHours/useOpeningHours'
import { usePhoneNumber } from 'core/services/phone/usePhoneNumber'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import moment from 'moment'
import React, { useState } from 'react'
import { CardAsset } from './common/cardAsset'
import Section from './common/section'
import { Title } from './common/title'
import { UserText } from './common/userText'

interface Props {
    establishment: EstablishmentModel
    color: 'primary' | 'secondary'
    hubSpotFormInitialized: boolean
    formId?: string
}

const OrderCardContent = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
    },
})) as typeof CardContent

const ActionButton = styled(SubmitButton)(({ theme }) => ({
    'width': 350,
    'fontSize': '1.3rem',
    'marginTop': theme.spacing(3),
    '.MuiButton-startIcon>*:nth-of-type(1)': {
        fontSize: '2rem',
    },
    [theme.breakpoints.only('xs')]: {
        width: 280,
        fontSize: '1rem',
    },
})) as typeof SubmitButton

const OrderSection = React.forwardRef<HTMLDivElement, Props>(
    ({ establishment: { id, businessHours, specialClosing, phoneNumber, websiteSections }, color }, ref) => {
        const { t } = useTranslation()

        const sectionId = 'order'
        const title = t('menu.order')
        const section = websiteSections?.find((section) => section.section === sectionId)

        const theme = useTheme()
        const isMobileScreen = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true })
        const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
        const { displayPhone, getPhoneNumber } = usePhoneNumber()
        const { shouldDisableTime, shouldDisableDate } = useOpeningHours(businessHours, specialClosing)
        const [inProgress, setInProgress] = useState(false)

        const spacing = isMobileScreen ? 2 : 4

        const handleOrder = (values: OrderFormState) => {
            const orderDate = values.date
            orderDate.hour(values.time.hour()).minute(values.time.minute()).second(0)
            const phone = parsePhoneNumberFromString(values.phone, 'FR')
            if (phone === undefined || !phone.isValid()) {
                return
            }
            setInProgress(true)
            mutations
                .order({
                    account: id.toString(),
                    input: {
                        establishmentId: id.toString(),
                        orderDate: orderDate.format(),
                        givenName: values.givenName,
                        familyName: values.familyName,
                        email: values.email,
                        phone: phone.number as string,
                        content: values.comment,
                    },
                })
                .then(() => {
                    setFormSubmitted(true)
                    // @ts-ignore
                    window.scrollTo('#order')
                })
                .catch((error: any) => {
                    console.error(error)
                })
                .finally(() => {
                    setInProgress(false)
                })
        }

        const initialValues = (() => {
            const now = moment()
            let nextDate = now.clone().hour(0).minute(0)
            while (shouldDisableDate(nextDate)) {
                nextDate = nextDate.add(1, 'day')
            }
            let nextHour = nextDate
            while (nextHour.isBefore(now.clone().add(1, 'hour'), 'hours')) {
                nextHour = nextHour.add(1, 'hour')
            }
            while (shouldDisableTime(nextHour, nextHour.hour(), 'hours')) {
                nextHour = nextHour.add(1, 'hour')
            }
            const remainder = nextHour.minute() % 5
            let nextMinute = nextHour.add(remainder, 'minutes')
            while (nextMinute.isBefore(now.clone().add(1, 'hour'), 'minutes')) {
                nextMinute = nextMinute.add(5, 'minutes')
            }
            while (shouldDisableTime(nextMinute, nextMinute.minutes(), 'minutes', nextMinute.hour())) {
                nextMinute = nextMinute.add(5, 'minutes')
            }
            return {
                givenName: '',
                familyName: '',
                email: '',
                phone: '',
                date: nextDate,
                time: nextMinute,
                comment: '',
            }
        })()
        return (
            <Section
                ref={ref}
                id={sectionId}
                title={title}
                color={color}
                backgroundImage={section?.backgroundImage ?? undefined}
                backgroundColor={section?.backgroundColor ?? undefined}
            >
                <Container maxWidth="md">
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            zIndex: 3,
                        }}
                        spacing={spacing}
                    >
                        <Grid item xs={12}>
                            <Title
                                component="h2"
                                color={color}
                                sx={{
                                    color: section?.textColor,
                                }}
                            >
                                {title}
                            </Title>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <OrderForm
                                    inProgress={inProgress}
                                    onSubmit={handleOrder}
                                    initialValues={initialValues}
                                    shouldDisableDate={shouldDisableDate}
                                    shouldDisableTime={shouldDisableTime}
                                >
                                    {(content, actions) => (
                                        <OrderCardContent>
                                            {formSubmitted ? (
                                                <Typography>
                                                    <Trans
                                                        i18nKey="order.success"
                                                        values={{
                                                            phoneNumber: displayPhone(phoneNumber),
                                                        }}
                                                        components={[
                                                            <Link href={getPhoneNumber(phoneNumber) ?? ''} />,
                                                        ]}
                                                    />
                                                </Typography>
                                            ) : (
                                                <>
                                                    <UserText sx={{ marginBottom: 2 }}>
                                                        <Trans
                                                            i18nKey="order.message"
                                                            values={{
                                                                phoneNumber: displayPhone(phoneNumber),
                                                            }}
                                                            components={[
                                                                <Link
                                                                    href={getPhoneNumber(phoneNumber) ?? ''}
                                                                />,
                                                            ]}
                                                        />
                                                    </UserText>
                                                    {content}
                                                    <CardActions sx={{ justifyContent: 'center' }}>
                                                        {actions.map((action, index) => (
                                                            <ActionButton
                                                                key={'orderAction' + index}
                                                                variant="contained"
                                                                color="primary"
                                                                {...action}
                                                            />
                                                        ))}
                                                    </CardActions>
                                                </>
                                            )}
                                        </OrderCardContent>
                                    )}
                                </OrderForm>
                            </Card>
                            <CardAsset
                                color={color}
                                sx={{
                                    color: section?.textColor,
                                }}
                            >
                                <Trans
                                    i18nKey="order.propelBy"
                                    components={[
                                        <Link
                                            href="https://www.sugg1144.com"
                                            color={color === 'primary' ? 'secondary' : 'primary'}
                                            sx={{
                                                color: section?.textColor,
                                                textDecorationColor: section?.textColor,
                                            }}
                                        />,
                                    ]}
                                />
                            </CardAsset>
                        </Grid>
                    </Grid>
                </Container>
            </Section>
        )
    },
)

export default OrderSection
