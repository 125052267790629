import { Card, CardContent, Container, Grid, styled, Typography } from '@mui/material'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import Section from './common/section'
import { Title } from './common/title'

interface Props {
    establishment: EstablishmentModel
    color: 'primary' | 'secondary'
}

const AboutCardContent = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
    },
})) as typeof CardContent

const Message = styled(Typography)(({ theme, color }) => ({
    whiteSpace: 'pre-line',
    paddingBottom: theme.spacing(2),
    color: color === 'primary' ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText,
    fontSize: '1.2rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.85rem',
    },
})) as typeof Typography

const AboutSection = React.forwardRef<HTMLDivElement, Props>(
    ({ establishment: { description, websiteSections }, color }, ref) => {
        const { t } = useTranslation()

        const id = 'about'
        const title = t('menu.about')
        const section = websiteSections?.find((section) => section.section === id)
        return (
            <Section
                ref={ref}
                id={id}
                title={title}
                color={color}
                backgroundImage={section?.backgroundImage ?? undefined}
                backgroundColor={section?.backgroundColor ?? undefined}
            >
                <Container maxWidth="sm">
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            zIndex: 3,
                        }}
                    >
                        <Grid item xs={12}>
                            <Title
                                component="h2"
                                color={color}
                                sx={{
                                    color: section?.textColor,
                                }}
                            >
                                {title}
                            </Title>
                        </Grid>
                        <Grid item xs={12}>
                            {section?.backgroundImage ? (
                                <Card>
                                    <AboutCardContent>
                                        <Message color={color}>{description}</Message>
                                    </AboutCardContent>
                                </Card>
                            ) : (
                                <Message
                                    color={color}
                                    sx={{
                                        color: section?.textColor,
                                    }}
                                >
                                    {description}
                                </Message>
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </Section>
        )
    },
)

export default AboutSection
