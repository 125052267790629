import React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import EstablishmentView from 'components/views/establishmentView'
import { graphql } from 'gatsby'
import useHubSpot from 'core/services/hubspot/useHubSpot'

interface Props {
    data: {
        site: {
            siteMetadata: {
                siteUrl: string
            }
        }
    }
}

const Establishment: React.FC<Props> = ({
    data: {
        site: {
            siteMetadata: { siteUrl },
        },
    },
}) => {
    const hubSpotFormInitialized = useHubSpot()
    const { establishment, reviews } = useSelector(({ app }: ApplicationState) => ({
        establishment: app.establishment,
        reviews: app.reviews,
    }))

    return (
        <EstablishmentView
            establishment={establishment}
            reviews={reviews}
            siteUrl={siteUrl}
            hubSpotFormInitialized={hubSpotFormInitialized}
        />
    )
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`

export default Establishment
