import { Box, Hidden } from '@mui/material'
import ZoomDialog from 'components/dialogs/zoomDialog'
import withMuiTheme from 'containers/hoc/withMuiTheme'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { ReviewsModel } from 'core/models/reviewsModel'
import { useImgix } from 'core/services/imgix/useImgix'
import { usePhoneNumber } from 'core/services/phone/usePhoneNumber'
import { useWebsite } from 'core/services/website/useWebsite'
import { Helmet, useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'
import SmartBanner from 'react-smartbanner'
import GamingDrawer from '../drawer/gamingDrawer'
import AboutSection from './establishment/aboutSection'
import FooterSection from './establishment/footerSection'
import Header from './establishment/header'
import InformationSection from './establishment/informationSection'
import LinksSection from './establishment/linksSection'
import MenuSection from './establishment/menuSection'
import NewsSection from './establishment/newsSection'
import PhotosSection from './establishment/photosSection'
import ReviewsSection from './establishment/reviewsSection'
import SlateSection from './establishment/slateSection'
import TitleSection from './establishment/titleSection'
import Footer from './footer'

interface Props {
    establishment: EstablishmentModel
    reviews?: ReviewsModel
    siteUrl: string
    hubSpotFormInitialized: boolean
}

const EstablishmentView: React.FC<Props> = ({ establishment, reviews, siteUrl, hubSpotFormInitialized }) => {
    const { t } = useTranslation()
    const { validPhone } = usePhoneNumber()
    const { isValidWebsite } = useWebsite()

    let canonicalUrl = siteUrl + '/restaurant/' + establishment.uriKey + '.html'
    if (process.env.GATSBY_CUSTOMER) {
        canonicalUrl = siteUrl
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const listener = (e: PopStateEvent) => {
                const target = e.target as Window | null
                if (target && target.location.hash === '') {
                    window.scroll({ top: 0, left: 0, behavior: 'auto' })
                }
            }
            window.history.scrollRestoration = 'manual'
            window.addEventListener('popstate', listener)
            return () => {
                window.removeEventListener('popstate', listener)
            }
        }
    }, [])

    const [zoom, setZoom] = useState<string | undefined>(undefined)

    const sections: Array<{
        key: string
        Component: React.ElementType
        color: 'primary' | 'secondary'
    }> = []
    let index = 0
    if (establishment.menuBoard) {
        sections.push({
            key: 'SlateSection',
            Component: SlateSection,
            color: index % 2 === 1 ? 'primary' : 'secondary',
        })
        index++
    }
    if (establishment.menu && establishment.menu.length > 0) {
        sections.push({
            key: 'MenuSection',
            Component: MenuSection,
            color: index % 2 === 1 ? 'primary' : 'secondary',
        })
        index++
    }
    if (establishment.newsList && establishment.newsList.length > 0) {
        sections.push({
            key: 'NewsSection',
            Component: NewsSection,
            color: index % 2 === 1 ? 'primary' : 'secondary',
        })
        index++
    }
    sections.push({
        key: 'AboutSection',
        Component: AboutSection,
        color: index % 2 === 1 ? 'primary' : 'secondary',
    })
    index++
    if (
        validPhone(establishment.phoneNumber) ||
        establishment.email ||
        isValidWebsite(establishment.website) ||
        establishment.delivery ||
        establishment.booking
    ) {
        sections.push({
            key: 'LinksSection',
            Component: LinksSection,
            color: index % 2 === 1 ? 'primary' : 'secondary',
        })
        index++
        // Has Booking module
        if (
            typeof window !== 'undefined' &&
            hubSpotFormInitialized &&
            establishment.bookingModule &&
            validPhone(establishment.phoneNumber) &&
            establishment.email &&
            establishment.businessHours &&
            !(
                establishment.businessHours?.sunday?.length === 0 &&
                establishment.businessHours?.monday?.length === 0 &&
                establishment.businessHours?.tuesday?.length === 0 &&
                establishment.businessHours?.wednesday?.length === 0 &&
                establishment.businessHours?.thursday?.length === 0 &&
                establishment.businessHours?.friday?.length === 0 &&
                establishment.businessHours?.saturday?.length === 0
            )
        ) {
            index++
        }
        // Has Order module
        if (
            typeof window !== 'undefined' &&
            hubSpotFormInitialized &&
            establishment.orderModule &&
            validPhone(establishment.phoneNumber) &&
            establishment.email &&
            establishment.businessHours &&
            !(
                establishment.businessHours?.sunday?.length === 0 &&
                establishment.businessHours?.monday?.length === 0 &&
                establishment.businessHours?.tuesday?.length === 0 &&
                establishment.businessHours?.wednesday?.length === 0 &&
                establishment.businessHours?.thursday?.length === 0 &&
                establishment.businessHours?.friday?.length === 0 &&
                establishment.businessHours?.saturday?.length === 0
            )
        ) {
            index++
        }
    }
    if (establishment.pictures && establishment.pictures.length > 0) {
        sections.push({
            key: 'PhotosSection',
            Component: PhotosSection,
            color: index % 2 === 1 ? 'primary' : 'secondary',
        })
        index++
    }
    sections.push({
        key: 'InformationSection',
        Component: InformationSection,
        color: index % 2 === 1 ? 'primary' : 'secondary',
    })
    index++
    sections.push({
        key: 'ReviewsSection',
        Component: ReviewsSection,
        color: index % 2 === 1 ? 'primary' : 'secondary',
    })
    index++
    sections.push({
        key: 'FooterSection',
        Component: FooterSection,
        color: index % 2 === 1 ? 'primary' : 'secondary',
    })
    index++

    const handleZoom = (image: string) => {
        setZoom(image)
    }
    const handleClose = () => {
        setZoom(undefined)
    }

    const displaySections = () => {
        return (
            <>
                {sections.map(({ Component, color, key }) => (
                    <Component
                        key={key}
                        establishment={establishment}
                        reviews={reviews}
                        color={color}
                        onZoom={handleZoom}
                        canonicalUrl={canonicalUrl}
                        hubSpotFormInitialized={hubSpotFormInitialized}
                    />
                ))}
            </>
        )
    }
    const { getImage } = useImgix()
    let imageUrl = undefined
    if (establishment.picture) {
        imageUrl = getImage(establishment.picture, { w: 800, h: 800 })
    }
    let description = establishment.description?.replace('\\n', ' ') ?? ''
    if (establishment.menuBoardData) {
        const slate = establishment.menuBoardData
        description = slate.comment.replace('\\n', ' ')
        if (slate.picture) {
            imageUrl = getImage(slate.picture, { w: 800, h: 800 })
        }
    }
    return (
        <Box itemScope itemType="https://schema.org/Restaurant">
            <Helmet>
                <title>
                    {t('establishment.title', {
                        name: establishment.name,
                        city: establishment.completeAddress.city,
                    })}
                </title>
                <meta name="description" content={description} />
                <meta property="og:type" content="website" />
                <meta
                    property="og:site_name"
                    content={t('establishment.title', {
                        name: establishment.name,
                        city: establishment.completeAddress.city,
                    })}
                />
                <meta property="og:image" content={imageUrl} />
                <meta
                    property="og:title"
                    content={t('establishment.title', {
                        name: establishment.name,
                        city: establishment.completeAddress.city,
                    })}
                />
                <meta property="og:description" content={description} />
                <link rel="canonical" href={canonicalUrl} />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <SmartBanner
                title="OnVaAuResto - RemplisVert"
                author="Sugg-Gestion"
                button="Installer"
                storeText={{ android: 'Sur Google Play', ios: "dans l'App Store" }}
                price={{ android: 'GRATUIT', ios: 'OBTENIR' }}
            />
            <GamingDrawer link={establishment.interactiveGame} />
            <Hidden mdDown implementation="js">
                <Header establishment={establishment} />
            </Hidden>
            <TitleSection establishment={establishment} />
            {displaySections()}
            <Footer />
            {zoom && <ZoomDialog open={true} image={zoom} onClose={handleClose} />}
        </Box>
    )
}

export default withMuiTheme(EstablishmentView)
