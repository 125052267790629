import { TextField } from '@mui/material'
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers'
import { MuiTextFieldProps } from '@mui/x-date-pickers/internals'
import { TimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useTimeValidation'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Moment } from 'moment'
import React, { useState } from 'react'
import { Field } from 'react-final-form'

interface Props {
    name: string
    helperText?: React.ReactNode
    label?: MuiTextFieldProps['label']
    shouldDisableTime?: (
        timeValue: number,
        clockType: 'hours' | 'minutes' | 'seconds',
        hour?: number,
    ) => boolean
    margin?: 'dense' | 'normal' | 'none'
    minutesStep?: number
    required?: boolean
    minTime?: Moment
}

interface TimePickerWithHourProps
    extends TimePickerProps<Moment, Moment>,
        React.RefAttributes<HTMLDivElement> {
    shouldDisableTime?: (
        timeValue: number,
        clockType: 'hours' | 'minutes' | 'seconds',
        hour?: number,
    ) => boolean
}

const TimePickerWithHour: React.FC<TimePickerWithHourProps> = (props) => {
    const [hour, setHour] = useState<number>()
    const shouldDisableTime = (timeValue: number, clockType: 'hours' | 'minutes' | 'seconds') => {
        if (props.shouldDisableTime) {
            if (clockType === 'hours') {
                setHour(timeValue)
            }
            return props.shouldDisableTime(timeValue, clockType, hour)
        }
        return false
    }
    return <TimePicker {...props} shouldDisableTime={shouldDisableTime} />
}

const TimeField: React.FC<Props> = ({
    name,
    helperText,
    label,
    shouldDisableTime,
    minutesStep,
    margin = 'normal',
    minTime,
    required,
}) => {
    const { t } = useTranslation()
    const [error, setError] = useState<TimeValidationError>()

    return (
        <Field name={name}>
            {({ input: { onChange, value }, meta }) => {
                const showError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <TimePickerWithHour
                        minTime={minTime}
                        label={label}
                        value={value}
                        onChange={onChange}
                        shouldDisableTime={shouldDisableTime}
                        minutesStep={minutesStep}
                        onError={(reason) => setError(reason)}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                required={required}
                                margin={margin}
                                error={showError || params.error}
                                helperText={
                                    showError
                                        ? meta.error || meta.submitError
                                        : helperText ?? (error ? t('formValidation.' + error) : '')
                                }
                                {...params}
                            />
                        )}
                    />
                )
            }}
        </Field>
    )
}

export default TimeField
