import { CardGiftcard, Facebook, Google, Instagram, Language } from '@mui/icons-material'
import { Container, Divider, Grid, IconButton, styled, Typography } from '@mui/material'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import Section from './common/section'

interface Props {
    establishment: EstablishmentModel
    color: 'primary' | 'secondary'
}

export const FooterText = styled(Typography)(({ theme, color }) => ({
    color: color === 'primary' ? theme.palette.secondary.main : theme.palette.primary.main,
    fontSize: '0.8rem',
    textAlign: 'center',
})) as typeof Typography

export const FooterDivider = styled(Divider)(({ theme, color }) => ({
    backgroundColor: color === 'primary' ? theme.palette.secondary.main : theme.palette.primary.main,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: 0,
})) as typeof Divider

export const FooterIconButton = styled(IconButton)(({ theme, color }) => ({
    'borderColor': color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
    'borderStyle': 'solid',
    'borderWidth': 1,
    '&:hover': {
        backgroundColor: color === 'primary' ? theme.palette.primary.light : theme.palette.secondary.dark,
    },
})) as typeof IconButton

const FooterSection = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            establishment: {
                name,
                type,
                foodType,
                completeAddress,
                facebookUrl,
                interactiveGame,
                instagramUrl,
                googleUrl,
                website,
                websiteSections,
            },
            color,
        },
        ref,
    ) => {
        const { t } = useTranslation()

        const id = 'footer'
        const title = t('menu.footer')
        const section = websiteSections?.find((section) => section.section === id)

        return (
            <Section
                ref={ref}
                id={id}
                color={color}
                title={title}
                backgroundImage={section?.backgroundImage ?? undefined}
                backgroundColor={section?.backgroundColor ?? undefined}
            >
                <Container maxWidth="xl">
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            zIndex: 3,
                        }}
                        spacing={4}
                    >
                        <Grid item xs={12}>
                            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                                {facebookUrl && (
                                    <Grid item>
                                        <FooterIconButton
                                            href={facebookUrl}
                                            color={color === 'primary' ? 'secondary' : 'primary'}
                                            sx={{
                                                color: section?.textColor,
                                                borderColor: section?.textColor,
                                            }}
                                            size="large"
                                        >
                                            <Facebook fontSize="inherit" />
                                        </FooterIconButton>
                                    </Grid>
                                )}
                                {instagramUrl && (
                                    <Grid item>
                                        <FooterIconButton
                                            href={instagramUrl}
                                            color={color === 'primary' ? 'secondary' : 'primary'}
                                            size="large"
                                            sx={{
                                                color: section?.textColor,
                                                borderColor: section?.textColor,
                                            }}
                                        >
                                            <Instagram fontSize="inherit" />
                                        </FooterIconButton>
                                    </Grid>
                                )}
                                {googleUrl && (
                                    <Grid item>
                                        <FooterIconButton
                                            href={googleUrl}
                                            color={color === 'primary' ? 'secondary' : 'primary'}
                                            size="large"
                                            sx={{
                                                color: section?.textColor,
                                                borderColor: section?.textColor,
                                            }}
                                        >
                                            <Google fontSize="inherit" />
                                        </FooterIconButton>
                                    </Grid>
                                )}
                                {website && (
                                    <Grid item>
                                        <FooterIconButton
                                            href={website}
                                            color={color === 'primary' ? 'secondary' : 'primary'}
                                            size="large"
                                            sx={{
                                                color: section?.textColor,
                                                borderColor: section?.textColor,
                                            }}
                                        >
                                            <Language fontSize="inherit" />
                                        </FooterIconButton>
                                    </Grid>
                                )}
                                {interactiveGame && (
                                    <Grid item>
                                        <FooterIconButton
                                            href={interactiveGame}
                                            color={color === 'primary' ? 'secondary' : 'primary'}
                                            size="large"
                                            sx={{
                                                color: section?.textColor,
                                                borderColor: section?.textColor,
                                            }}
                                        >
                                            <CardGiftcard fontSize="inherit" />
                                        </FooterIconButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                                <Grid item>
                                    <FooterText
                                        color={color}
                                        sx={{
                                            color: section?.textColor,
                                        }}
                                    >
                                        {name}
                                    </FooterText>
                                </Grid>
                                <FooterDivider
                                    orientation="vertical"
                                    flexItem
                                    variant="middle"
                                    color={color}
                                    sx={{
                                        bgcolor: section?.textColor,
                                    }}
                                />
                                <Grid item>
                                    <FooterText
                                        color={color}
                                        sx={{
                                            color: section?.textColor,
                                        }}
                                    >
                                        {type}
                                    </FooterText>
                                </Grid>
                                <FooterDivider
                                    orientation="vertical"
                                    flexItem
                                    variant="middle"
                                    color={color}
                                    sx={{
                                        bgcolor: section?.textColor,
                                    }}
                                />
                                <Grid item sx={{ maxWidth: '60vw' }}>
                                    <FooterText
                                        color={color}
                                        sx={{
                                            color: section?.textColor,
                                        }}
                                    >
                                        {foodType?.join(', ')}
                                    </FooterText>
                                </Grid>
                                <FooterDivider
                                    orientation="vertical"
                                    flexItem
                                    variant="middle"
                                    color={color}
                                    sx={{
                                        bgcolor: section?.textColor,
                                    }}
                                />
                                <Grid item>
                                    <FooterText
                                        color={color}
                                        sx={{
                                            color: section?.textColor,
                                        }}
                                    >
                                        <Trans
                                            i18nKey="footer.city"
                                            values={{
                                                city: completeAddress.city,
                                                country: t('countries.' + completeAddress.country),
                                            }}
                                        />
                                    </FooterText>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Section>
        )
    },
)

export default FooterSection
