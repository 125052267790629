export const cumulativeOffset = function (element?: HTMLElement) {
    let top = 0,
        left = 0
    if (element) {
        do {
            top += element?.offsetTop || 0
            left += element?.offsetLeft || 0
            // @ts-ignore
            element = element?.offsetParent
        } while (element)
    }
    return {
        top: top,
        left: left,
    }
}
