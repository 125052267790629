export interface ImgixParams {
    [key: string]: string | number | undefined
}

export const useImgix = () => {
    const getImage = (url: string, params?: ImgixParams) => {
        const defaultParams: ImgixParams = {
            auto: 'format',
            q: '70',
        }
        params = {
            ...defaultParams,
            ...params,
        }
        if (url.startsWith('data:image')) {
            return url
        }
        let queryString = url + '?rot=0'
        if (params) {
            for (let param in params) {
                if (params.hasOwnProperty(param) && params[param]) {
                    queryString += '&' + param + '=' + params[param]
                }
            }
        }
        return queryString
    }

    return {
        getImage,
    }
}
