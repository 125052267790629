import { gql } from '@apollo/client'
import { client } from './client'
import { BookRequest, OrderRequest } from './type'

const book = ({ account, input }: BookRequest): Promise<string> => {
    return client
        .mutate({
            mutation: gql`
                mutation ($account: String!, $input: BookingInput!) {
                    book(account: $account, input: $input) {
                        id
                    }
                }
            `,
            variables: {
                account,
                input,
            },
        })
        .then((result) => result.data.book.id)
}

const order = ({ account, input }: OrderRequest): Promise<string> => {
    return client
        .mutate({
            mutation: gql`
                mutation ($account: String!, $input: OrderInput!) {
                    order(account: $account, input: $input) {
                        id
                    }
                }
            `,
            variables: {
                account,
                input,
            },
        })
        .then((result) => result.data.order.id)
}

export const mutations = {
    book,
    order,
}
