import React from 'react'
import { SpecialClosingModel } from 'core/models/specialClosingModel'
import { Grid, Typography } from '@mui/material'
import { Trans } from 'gatsby-plugin-react-i18next'
import moment from 'moment'
import { CardTitle } from '../common/cardTitle'

interface Props {
    specialClosings: Array<SpecialClosingModel>
    color: 'primary' | 'secondary'
}

const SpecialClosing: React.FC<Props> = ({ specialClosings, color }) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <CardTitle component="h3" color={color}>
                    <Trans i18nKey="establishment.specialClosing" />
                </CardTitle>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    {specialClosings.length === 0 && (
                        <Grid item xs={12}>
                            <Typography>
                                <Trans i18nKey="establishment.noSpecialClosing" />
                            </Typography>
                        </Grid>
                    )}
                    {specialClosings.map((specialClosing, index) => (
                        <Grid item xs={12} key={'specialClosings' + index}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontWeight: '600',
                                        }}
                                    >
                                        <Trans
                                            i18nKey="establishment.specialClosingLabel"
                                            values={{ title: specialClosing.label }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        {moment(specialClosing.dateStart).isSame(
                                            moment(specialClosing.dateEnd),
                                        ) ? (
                                            <Trans
                                                i18nKey="establishment.specialClosingDateSame"
                                                values={{
                                                    dateStart: moment(specialClosing.dateStart).format(
                                                        'dddd D MMMM',
                                                    ),
                                                }}
                                            />
                                        ) : (
                                            <Trans
                                                i18nKey="establishment.specialClosingDate"
                                                values={{
                                                    dateStart: moment(specialClosing.dateStart).format(
                                                        'dddd D MMMM',
                                                    ),
                                                    dateEnd: moment(specialClosing.dateEnd).format(
                                                        'dddd D MMMM',
                                                    ),
                                                }}
                                            />
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SpecialClosing
