import * as React from 'react'
import { SvgIcon } from '@mui/material'

const BookingIcon: React.FC<any> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 1024 1024">
            <path d="M870.4 0h-716.8c-84.791 0.094-153.506 68.809-153.6 153.6v375.467c0.094 84.791 68.809 153.506 153.6 153.6h256v68.267h51.2v238.933h-153.6v34.133h409.6v-34.133h-153.6v-238.933h51.2v-68.267h256c84.791-0.094 153.506-68.809 153.6-153.6v-375.467c-0.094-84.791-68.809-153.506-153.6-153.6zM529.067 989.867h-34.133v-238.933h34.133v238.933zM580.267 716.8h-136.533v-102.4h136.533v102.4zM989.867 529.067c-0.085 65.943-53.523 119.381-119.467 119.467h-256v-68.267h-204.8v68.267h-256c-65.943-0.085-119.381-53.523-119.467-119.467v-375.467c0.085-65.943 53.523-119.381 119.467-119.467h716.8c65.943 0.085 119.381 53.523 119.467 119.467v375.467z" />
            <path d="M168.875 384.905l-21.794-43.571h6.519c9.425 0 17.067-7.642 17.067-17.067v-68.267c0-9.425-7.642-17.067-17.067-17.067h-68.267c-9.425 0-17.067 7.642-17.067 17.067v136.533c0 9.425 7.642 17.067 17.067 17.067s17.067-7.642 17.067-17.067v-51.2h6.519l29.406 58.829c4.213 8.435 14.468 11.859 22.903 7.646s11.859-14.468 7.646-22.903zM136.533 307.2h-34.133v-34.133h34.133v34.133z" />
            <path d="M904.533 238.933h-34.133c-9.425 0-17.067 7.642-17.067 17.067v136.533c0 9.425 7.642 17.067 17.067 17.067h34.133c28.277 0 51.2-22.923 51.2-51.2v-68.267c0-28.277-22.923-51.2-51.2-51.2zM921.6 358.4c0 9.425-7.642 17.067-17.067 17.067h-17.067v-102.4h17.067c9.425 0 17.067 7.642 17.067 17.067v68.267z" />
            <path d="M614.4 324.267v-68.267c0-9.425-7.642-17.067-17.067-17.067h-68.267c-9.425 0-17.067 7.642-17.067 17.067v136.533c0 9.425 7.642 17.067 17.067 17.067s17.067-7.642 17.067-17.067v-51.2h6.519l29.321 58.829c4.213 8.435 14.468 11.859 22.903 7.646s11.859-14.468 7.646-22.903l-21.709-43.571h6.519c9.425 0 17.067-7.642 17.067-17.067zM580.267 307.2h-34.133v-34.133h34.133v34.133z" />
            <path d="M256 375.467h-34.133v-34.133h17.067c9.425 0 17.067-7.642 17.067-17.067s-7.642-17.067-17.067-17.067h-17.067v-34.133h34.133c9.425 0 17.067-7.642 17.067-17.067s-7.642-17.067-17.067-17.067h-51.2c-9.425 0-17.067 7.642-17.067 17.067v136.533c0 9.425 7.642 17.067 17.067 17.067h51.2c9.425 0 17.067-7.642 17.067-17.067s-7.642-17.067-17.067-17.067z" />
            <path d="M477.867 375.467h-34.133v-34.133h17.067c9.425 0 17.067-7.642 17.067-17.067s-7.642-17.067-17.067-17.067h-17.067v-34.133h34.133c9.425 0 17.067-7.642 17.067-17.067s-7.642-17.067-17.067-17.067h-51.2c-9.425 0-17.067 7.642-17.067 17.067v136.533c0 9.425 7.642 17.067 17.067 17.067h51.2c9.425 0 17.067-7.642 17.067-17.067s-7.642-17.067-17.067-17.067z" />
            <path d="M819.2 375.467h-34.133v-34.133h17.067c9.425 0 17.067-7.642 17.067-17.067s-7.642-17.067-17.067-17.067h-17.067v-34.133h34.133c9.425 0 17.067-7.642 17.067-17.067s-7.642-17.067-17.067-17.067h-51.2c-9.425 0-17.067 7.642-17.067 17.067v136.533c0 9.425 7.642 17.067 17.067 17.067h51.2c9.425 0 17.067-7.642 17.067-17.067s-7.642-17.067-17.067-17.067z" />
            <path d="M375.467 307.2h-51.2v-34.133h51.2c9.425 0 17.067-7.642 17.067-17.067s-7.642-17.067-17.067-17.067h-68.267c-9.425 0-17.067 7.642-17.067 17.067v68.267c0 9.425 7.642 17.067 17.067 17.067h51.2v34.133h-51.2c-9.425 0-17.067 7.642-17.067 17.067s7.642 17.067 17.067 17.067h68.267c9.425 0 17.067-7.642 17.067-17.067v-68.267c0-9.425-7.642-17.067-17.067-17.067z" />
            <path d="M720.93 239.445c-9.143-2.278-18.402 3.283-20.685 12.425l-17.579 70.298-17.579-70.298c-2.069-9.197-11.2-14.974-20.397-12.905s-14.974 11.2-12.905 20.397c0.058 0.258 0.122 0.514 0.192 0.768l34.133 136.533c2.291 9.143 11.561 14.697 20.704 12.405 6.108-1.53 10.876-6.3 12.405-12.405l34.133-136.533c2.278-9.143-3.283-18.402-12.425-20.685z" />
        </SvgIcon>
    )
}

export default BookingIcon
