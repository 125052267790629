import { Box, CardMedia, useMediaQuery, useTheme } from '@mui/material'
import { useImgix } from 'core/services/imgix/useImgix'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

interface Props {
    id?: string
    children: ReactElement
    color: 'primary' | 'secondary'
    title: string
    backgroundImage?: string
    backgroundColor?: string
    overlayOpacity?: number
}

const Section = React.forwardRef<HTMLDivElement, Props>(
    (
        { children, color, id, title, backgroundImage, backgroundColor = undefined, overlayOpacity = 0 },
        ref,
    ) => {
        const { getImage } = useImgix()
        const theme = useTheme()
        const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })

        const divRef = useRef<HTMLDivElement | null>(null)

        const [clientWidth, setClientWidth] = useState<number>(1440)
        const [clientHeight, setClientHeight] = useState<number>(600)

        useEffect(() => {
            if (divRef.current !== null) {
                setClientWidth(divRef.current.clientWidth)
                setClientHeight(divRef.current.clientHeight)
            }
        }, [])

        const getImageSource = (picture?: string, dpr?: number) => {
            const image = picture ?? process.env.GATSBY_ASSETS_URL + '/media/establishment/default.jpg'
            let width = 1440
            let height = 600
            if (typeof window !== 'undefined' && document) {
                width = clientWidth
                height = clientHeight
            }
            return getImage(image, { w: width, h: height, fit: 'crop', dpr })
        }
        const getRawImage = (picture?: string) => {
            return getImage(picture ?? process.env.GATSBY_ASSETS_URL + '/media/establishment/default.jpg')
        }

        return (
            <Box
                ref={divRef}
                component={'div'}
                sx={{
                    bgcolor:
                        backgroundColor ?? (color === 'primary' ? 'background.dark' : 'background.default'),
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: isMobileScreen ? 2 : 6,
                    paddingBottom: isMobileScreen ? 2 : 6,
                    position: 'relative',
                    flexDirection: 'column',
                }}
            >
                {backgroundImage && (
                    <Box
                        sx={{
                            position: 'absolute',
                            inset: 0,
                            opacity: 1 - overlayOpacity,
                        }}
                    >
                        <CardMedia
                            itemProp="image"
                            {...{ content: getRawImage(backgroundImage) }}
                            component={'img'}
                            srcSet={
                                getImageSource(backgroundImage, 1) +
                                ' 1x, ' +
                                getImageSource(backgroundImage, 2) +
                                ' 2x, ' +
                                getImageSource(backgroundImage, 3) +
                                ' 3x'
                            }
                            src={getImageSource(backgroundImage)}
                            alt={title}
                            width="100%"
                            height="100%"
                        />
                    </Box>
                )}

                <Box
                    component={'div'}
                    ref={ref}
                    id={id}
                    sx={{
                        position: 'absolute',
                        top: -56,
                    }}
                />
                <Box
                    component={'div'}
                    sx={{
                        zIndex: 3,
                        height: '100%',
                        width: '100%',
                    }}
                >
                    {children}
                </Box>
            </Box>
        )
    },
)

export default Section
