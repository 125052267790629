import {
    Box,
    CardMedia,
    Chip,
    Container,
    Grid,
    Stack,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { useImgix } from 'core/services/imgix/useImgix'
import React from 'react'

interface Props {
    establishment: EstablishmentModel
}

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: '3rem',
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: theme.spacing(1, 1),
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.8rem',
    },
})) as typeof Typography

const SubTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: '1.6rem',
    textAlign: 'center',
    padding: theme.spacing(1, 1),
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.4rem',
    },
})) as typeof Typography

const CategoryChip = styled(Chip)(({ theme }) => ({
    marginTop: theme.spacing(0.5) + ' !important',
    marginBottom: theme.spacing(0.5) + ' !important',
    [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0.25) + ' !important',
        marginBottom: theme.spacing(0.25) + ' !important',
        height: 20,
        fontSize: '0.6rem',
        marginLeft: 2 + 'px !important',
        marginRight: 2 + 'px !important',
    },
})) as typeof Chip

const TitleSection: React.FC<Props> = ({
    establishment: { name, type, picture, foodType, websiteSections },
}) => {
    const id = 'title'
    const section = websiteSections?.find((section) => section.section === id)

    const { getImage } = useImgix()
    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
    const isSmallScreen = useMediaQuery(theme.breakpoints.only('sm'), { noSsr: true })
    const getImageSource = (picture?: string, dpr?: number) => {
        const image = picture ?? process.env.GATSBY_ASSETS_URL + '/media/establishment/default.jpg'
        let width = 1440
        let height = 600
        if (typeof window !== 'undefined' && document) {
            width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
            height =
                (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) *
                0.7
        }
        return getImage(image, { w: width, h: height, fit: 'crop', dpr })
    }
    const getRawImage = (picture?: string) => {
        return getImage(picture ?? process.env.GATSBY_ASSETS_URL + '/media/establishment/default.jpg')
    }

    const image = section?.backgroundImage ?? picture
    return (
        <Box
            sx={{
                bgcolor: section?.backgroundColor ?? 'background.dark',
                height: isMobileScreen ? 'fit-content' : '70vh',
                minHeight: isMobileScreen ? 132 : isSmallScreen ? 180 : 278,
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                paddingBottom: 1,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    inset: 0,
                    opacity:
                        section?.overlayOpacity !== null && section?.overlayOpacity !== undefined
                            ? 1 - section.overlayOpacity
                            : 0.5,
                }}
            >
                <CardMedia
                    itemProp="image"
                    {...{ content: getRawImage(image) }}
                    component={'img'}
                    srcSet={
                        getImageSource(image, 1) +
                        ' 1x, ' +
                        getImageSource(image, 2) +
                        ' 2x, ' +
                        getImageSource(image, 3) +
                        ' 3x'
                    }
                    src={getImageSource(image)}
                    alt={name}
                    width="100%"
                    height="100%"
                />
            </Box>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                alignContent="space-evenly"
                sx={{
                    zIndex: 3,
                    paddingTop: isMobileScreen || isSmallScreen ? 0 : 8,
                    height: '100%',
                }}
            >
                <Grid item xs={12}>
                    <Title
                        component="h1"
                        itemProp="name"
                        sx={{
                            color: section?.textColor,
                        }}
                    >
                        {name}
                    </Title>
                    <SubTitle
                        component="h2"
                        sx={{
                            color: section?.textColor,
                        }}
                    >
                        {type}
                    </SubTitle>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="xl" disableGutters>
                        <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ paddingTop: isMobileScreen ? 1 : isSmallScreen ? 2 : 1 }}
                            flexWrap="wrap"
                        >
                            {foodType?.map((type, index) => (
                                <CategoryChip
                                    key={'foodTypeTitle' + index}
                                    size={isMobileScreen ? 'small' : 'medium'}
                                    itemProp="servesCuisine"
                                    label={type}
                                    variant="outlined"
                                    color="secondary"
                                    sx={{
                                        color: section?.textColor,
                                        borderColor: section?.textColor,
                                    }}
                                />
                            ))}
                        </Stack>
                    </Container>
                </Grid>
            </Grid>
        </Box>
    )
}

export default TitleSection
