import moment, { Moment } from 'moment'
import { BusinessHourModel, BusinessHoursModel } from 'core/models/businessHoursModel'
import { SpecialClosingModel } from '../../models/specialClosingModel'

export const useOpeningHours = (
    businessHours: BusinessHoursModel | null,
    specialClosing: Array<SpecialClosingModel>,
) => {
    const businessHourPerDay = (day: number) => {
        switch (day) {
            case 0:
                return businessHours?.sunday
            case 1:
                return businessHours?.monday
            case 2:
                return businessHours?.tuesday
            case 3:
                return businessHours?.wednesday
            case 4:
                return businessHours?.thursday
            case 5:
                return businessHours?.friday
            case 6:
                return businessHours?.saturday
        }
    }

    const shouldDisableDate = (day: Moment) => {
        let isClosed = false
        specialClosing.forEach((closing) => {
            if (
                !isClosed &&
                moment(closing.dateStart).isSameOrBefore(day, 'day') &&
                moment(closing.dateEnd).isSameOrAfter(day, 'day')
            ) {
                isClosed = true
                return
            }
        })
        if (isClosed) {
            return true
        }
        if (
            businessHours &&
            businessHours?.sunday?.length === 0 &&
            businessHours?.monday?.length === 0 &&
            businessHours?.tuesday?.length === 0 &&
            businessHours?.wednesday?.length === 0 &&
            businessHours?.thursday?.length === 0 &&
            businessHours?.friday?.length === 0 &&
            businessHours?.saturday?.length === 0
        ) {
            return true
        }
        let today = businessHourPerDay(day.day())
        let yesterday = businessHourPerDay(moment(day).add(-1, 'day').day())
        if (yesterday) {
            if (today && today.length > 0) {
                return false
            } else {
                if (yesterday.length > 0)
                    if (yesterday[yesterday.length - 1].endTime < yesterday[yesterday.length - 1].startTime) {
                        specialClosing.forEach((closing) => {
                            if (
                                !isClosed &&
                                moment(closing.dateStart).isSameOrBefore(moment(day).add(-1, 'day'), 'day') &&
                                moment(closing.dateEnd).isSameOrAfter(moment(day).add(-1, 'day'), 'day')
                            ) {
                                isClosed = true
                                return
                            }
                        })
                        return isClosed
                    }
            }
        }
        return (today?.length ?? 0) === 0
    }

    const parseTime = (timeValues: string, isEndTime?: boolean) => {
        const timeValue = timeValues.split(':')
        if (isEndTime) {
            if (timeValue[0] === '00') {
                timeValue[0] = '24'
            }
        }
        return {
            hour: parseInt(timeValue[0]),
            minute: parseInt(timeValue[0] + timeValue[1]),
            minuteOnly: parseInt(timeValue[1]),
        }
    }
    const shouldDisableHour = (businessHours: Array<BusinessHourModel>, timeValue: number) => {
        let isTimeValid = false
        businessHours.forEach((businessHour) => {
            if (
                !isTimeValid &&
                timeValue >= parseTime(businessHour.startTime).hour &&
                timeValue <= parseTime(businessHour.endTime).hour
            ) {
                isTimeValid = true
            }
            if (
                parseTime(businessHour.startTime).hour >= parseTime(businessHour.endTime).hour &&
                timeValue >= parseTime(businessHour.startTime).hour
            ) {
                isTimeValid = true
            }
        })
        return !isTimeValid
    }
    const shouldDisableMinute = (
        hour: number,
        businessHours: Array<BusinessHourModel>,
        timeValue: number,
    ) => {
        let isTimeValid = false
        businessHours.forEach((businessHour) => {
            if (parseTime(businessHour.startTime).minute < parseTime(businessHour.endTime, true).minute) {
                if (
                    !isTimeValid &&
                    parseInt(hour.toString() + timeValue.toString().padStart(2, '0')) >=
                        parseTime(businessHour.startTime).minute &&
                    parseInt(hour.toString() + timeValue.toString().padStart(2, '0')) <=
                        parseTime(businessHour.endTime, true).minute
                ) {
                    isTimeValid = true
                }
                if (
                    !isTimeValid &&
                    parseTime(businessHour.startTime).minute > parseTime(businessHour.endTime, true).minute
                ) {
                    isTimeValid = true
                }
            } else if (
                parseTime(businessHour.startTime).minute >= parseTime(businessHour.endTime, true).minute
            ) {
                if (!isTimeValid && parseTime(businessHour.startTime).hour === hour) {
                    if (timeValue >= parseTime(businessHour.startTime).minuteOnly) {
                        isTimeValid = true
                    }
                } else if (
                    !isTimeValid &&
                    parseInt(hour.toString() + timeValue.toString().padStart(2, '0')) >=
                        parseTime(businessHour.startTime).minute &&
                    parseInt(hour.toString() + timeValue.toString().padStart(2, '0')) <=
                        parseTime(businessHour.endTime, true).minute - 100
                ) {
                    isTimeValid = true
                } else if (
                    !isTimeValid &&
                    parseTime(businessHour.startTime).minute > parseTime(businessHour.endTime, true).minute
                ) {
                    isTimeValid = true
                }
            }
        })
        return !isTimeValid
    }
    const shouldDisableTimeForDay = (
        businessHours: Array<BusinessHourModel>,
        timeValue: number,
        clockType: 'hours' | 'minutes' | 'seconds',
        hour?: number,
    ) => {
        switch (clockType) {
            case 'hours':
                return shouldDisableHour(businessHours, timeValue)
            case 'minutes':
                if (!hour) {
                    return false
                }
                return shouldDisableMinute(hour, businessHours, timeValue)
        }

        return false
    }
    const shouldDisableTime = (
        day: Moment,
        timeValue: number,
        clockType: 'hours' | 'minutes' | 'seconds',
        hour?: number,
    ) => {
        let yesterday = businessHourPerDay(moment(day).add(-1, 'day').day())
        let today = businessHourPerDay(day.day())
        if (yesterday) {
            if (yesterday.length > 0) {
                if (
                    yesterday[yesterday.length - 1].endTime < yesterday[yesterday.length - 1].startTime &&
                    timeValue < parseTime(yesterday[yesterday.length - 1].endTime).hour
                ) {
                    return false
                }
            }
        }
        return shouldDisableTimeForDay(today ?? [], timeValue, clockType, hour)
    }

    return {
        shouldDisableDate,
        shouldDisableTime,
    }
}
