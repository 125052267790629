import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const usePhoneNumber = () => {
    const validPhone = (phone?: string) => {
        if (phone === undefined || phone === '' || phone === null) {
            return false
        }
        return parsePhoneNumberFromString(phone, 'FR') !== undefined
    }

    const displayPhone = (phone?: string) => {
        if (phone === undefined || phone === '' || phone === null) {
            return undefined
        }
        const phoneNumber = parsePhoneNumberFromString(phone, 'FR')
        if (phoneNumber === undefined) {
            return undefined
        }
        return phoneNumber.formatNational()
    }

    const getInternationalPhone = (phone?: string) => {
        if (phone === undefined || phone === '' || phone === null) {
            return undefined
        }
        const phoneNumber = parsePhoneNumberFromString(phone, 'FR')
        if (phoneNumber === undefined) {
            return undefined
        }
        return phoneNumber.number
    }

    const getPhoneNumber = (phone?: string) => {
        if (phone === undefined || phone === '' || phone === null) {
            return undefined
        }
        const phoneNumber = parsePhoneNumberFromString(phone, 'FR')
        if (phoneNumber === undefined) {
            return undefined
        }
        return phoneNumber.getURI()
    }

    return {
        validPhone,
        displayPhone,
        getPhoneNumber,
        getInternationalPhone,
    }
}
