import React from 'react'
import { NewsModel } from 'core/models/newsModel'
import { Card, CardActions, CardContent, IconButton, Link } from '@mui/material'
import { Trans } from 'gatsby-plugin-react-i18next'
import { UserText } from '../common/userText'
import { Anchorme } from 'react-anchorme'
import { Facebook, Google, Instagram, Twitter } from '@mui/icons-material'
import { NewsDate, NewsTitle } from '../newsSection'
import { EstablishmentModel } from 'core/models/establishmentModel'
import moment from 'moment'
import ImageContainer from '../../imageContainer'

interface Props {
    news: NewsModel
    getImageSource: (
        picture?: string | null,
        dpr?: number,
    ) => { width: number; height: number; image: string }
    name: EstablishmentModel['name']
    completeAddress: EstablishmentModel['completeAddress']
    onZoom: (image: string) => void
    canonicalUrl: string
    color: 'primary' | 'secondary'
}

const NewsCard: React.FC<Props> = ({
    news,
    getImageSource,
    name,
    completeAddress,
    onZoom,
    canonicalUrl,
    color,
}) => {
    return (
        <Card itemScope itemType="https://schema.org/NewsArticle">
            <CardContent>
                <span itemProp="contentLocation" itemScope itemType="https://schema.org/Place">
                    <meta itemProp="name" content={name} />
                    <span itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
                        <meta
                            itemProp="streetAddress"
                            content={completeAddress.street_1 + ' ' + completeAddress.street_2}
                        />
                        <meta itemProp="postalCode" content={completeAddress.zip_code} />
                        <meta itemProp="addressLocality" content={completeAddress.city} />
                        <meta itemProp="addressCountry" content={completeAddress.country} />
                    </span>
                </span>
                <span itemProp="organizer" itemScope itemType="https://schema.org/Restaurant">
                    <meta itemProp="name" content={name} />
                    <meta itemProp="url" content={canonicalUrl} />
                </span>
                <span itemProp="author" itemScope itemType="https://schema.org/Restaurant">
                    <meta itemProp="name" content={name} />
                    <meta itemProp="url" content={canonicalUrl} />
                </span>
                <NewsTitle component="h3" itemProp="headline">
                    <Trans i18nKey="establishment.news" />
                </NewsTitle>
                <NewsDate itemProp="datePublished" {...{ content: moment(news.createdAt).toISOString() }}>
                    <Trans
                        i18nKey="establishment.newsCreatedAt"
                        values={{ createdAt: moment(news.createdAt).format('D MMM') }}
                    />
                </NewsDate>
            </CardContent>
            {/* Images */}
            {news.images !== null && news.images !== undefined && news.images.length > 0 && (
                <ImageContainer news={news} onZoom={onZoom} getImageSource={getImageSource} color={color} />
            )}
            <CardContent>
                {news.comment && news.comment !== '' && (
                    <UserText
                        itemProp="articleBody"
                        {...{
                            content: news.comment,
                        }}
                    >
                        <Anchorme
                            linkComponent={(props) => (
                                // @ts-ignore
                                <Link {...props} />
                            )}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {news.comment ?? ''}
                        </Anchorme>
                    </UserText>
                )}

                <CardActions sx={{ justifyContent: 'center' }}>
                    {news?.facebookPermalink && (
                        <IconButton href={news.facebookPermalink} target="_blank" rel="noopener">
                            <Facebook />
                        </IconButton>
                    )}
                    {news?.instagramPermalink && (
                        <IconButton href={news.instagramPermalink} target="_blank" rel="noopener">
                            <Instagram />
                        </IconButton>
                    )}
                    {news?.googleLocalPostPermalink && (
                        <IconButton href={news.googleLocalPostPermalink} target="_blank" rel="noopener">
                            <Google />
                        </IconButton>
                    )}
                    {news?.twitterPermalink && (
                        <IconButton href={news.twitterPermalink} target="_blank" rel="noopener">
                            <Twitter />
                        </IconButton>
                    )}
                </CardActions>
            </CardContent>
        </Card>
    )
}

export default NewsCard
