import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'

const httpLink = createHttpLink({
    uri: process.env.GATSBY_GRAPHQL_API_URL,
    headers: {
        'x-api-key': process.env.GATSBY_GRAPHQL_API_KEY ?? '',
    },
})

export const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
})
