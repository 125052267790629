import React, { useEffect, useState } from 'react'
import { Divider, Grid, styled, Typography } from '@mui/material'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { BusinessHourModel, BusinessHoursModel } from 'core/models/businessHoursModel'
import { SpecialClosingModel } from 'core/models/specialClosingModel'
import moment from 'moment'
import { CardTitle } from '../common/cardTitle'

interface Props {
    businessHours: BusinessHoursModel
    specialClosing: Array<SpecialClosingModel>
    color: 'primary' | 'secondary'
}

const GridHour = styled(Grid)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
})) as typeof Grid

const BusinessHours: React.FC<Props> = ({ color, businessHours, specialClosing }) => {
    const { t } = useTranslation()
    const [dayOfWeek, setDayOfWeek] = useState(moment().day())

    useEffect(() => {
        setDayOfWeek(moment().day())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moment().day()])

    const getDatesOfWeek = () => {
        const days = []
        const dateStartOfWeek = moment().startOf('week')
        const dateEndOfWeek = moment().endOf('week').isoWeekday(7)
        let day = dateStartOfWeek
        while (day <= dateEndOfWeek) {
            days.push(day.toDate())
            day = day.clone().add(1, 'd')
        }
        return days
    }
    const checkIfDateInSpecialClosing = (date: Date) => {
        let val = ''
        const dateMoment = moment(date)
        specialClosing?.forEach((aSpecialClosing) => {
            const dateStartMoment = moment(aSpecialClosing.dateStart)
            const dateEndMoment = moment(aSpecialClosing.dateEnd)
            if (
                (dateMoment.isBefore(dateEndMoment, 'day') && dateMoment.isAfter(dateStartMoment, 'day')) ||
                dateMoment.isSame(dateStartMoment, 'day') ||
                dateMoment.isSame(dateEndMoment, 'day')
            ) {
                val = aSpecialClosing.label
                return
            }
        })
        return val
    }
    const datesOfWeek: Array<Date> = getDatesOfWeek()
    const getHours = (hours: Array<BusinessHourModel>) => {
        const hourList: Array<string> = []
        hours.forEach((hour) => {
            hourList.push(hour.startTime + '\u00a0-\u00a0' + hour.endTime)
        })
        if (hourList.length === 0) {
            return 'Fermé'
        }
        return hourList.join('\n')
    }
    const getMetaHours = (hours: Array<BusinessHourModel>) => {
        const hourList: Array<string> = []
        hours.forEach((hour) => {
            hourList.push(hour.startTime + '-' + hour.endTime)
        })
        return hourList.join(' ')
    }

    const getDayOfWeek = (dayOfWeek: number) => {
        switch (dayOfWeek) {
            case 0:
                return t('days.sunday')
            case 1:
                return t('days.monday')
            case 2:
                return t('days.tuesday')
            case 3:
                return t('days.wednesday')
            case 4:
                return t('days.thursday')
            case 5:
                return t('days.friday')
            case 6:
                return t('days.saturday')
        }
        return t('days.monday')
    }

    const getMetaDayOfWeek = (dayOfWeek: number) => {
        switch (dayOfWeek) {
            case 0:
                return 'Su'
            case 1:
                return 'Mo'
            case 2:
                return 'Tu'
            case 3:
                return 'We'
            case 4:
                return 'Th'
            case 5:
                return 'Fr'
            case 6:
                return 'Sa'
        }
        return 'Mo'
    }
    const days = []
    let i = 1
    const hoursComplete = {
        monday: businessHours?.monday ?? [],
        tuesday: businessHours?.tuesday ?? [],
        wednesday: businessHours?.wednesday ?? [],
        thursday: businessHours?.thursday ?? [],
        friday: businessHours?.friday ?? [],
        saturday: businessHours?.saturday ?? [],
        sunday: businessHours?.sunday ?? [],
    }
    for (const hour in hoursComplete) {
        if (hoursComplete.hasOwnProperty(hour)) {
            let value = getHours(
                hoursComplete[
                    hour as 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'
                ] ?? [],
            )
            const meta = getMetaHours(
                hoursComplete[
                    hour as 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'
                ] ?? [],
            )
            const isDateInSpecialClosing = checkIfDateInSpecialClosing(datesOfWeek[i - 1])
            if (isDateInSpecialClosing !== '') {
                value = isDateInSpecialClosing
            }
            days.push({
                label: getDayOfWeek(i % 7),
                value,
                today: i === dayOfWeek,
                previousDay: i === (dayOfWeek - 1) % 7,
                metaValue: meta,
                metaDay: getMetaDayOfWeek(i % 7),
            })
            i++
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <CardTitle component="h3" color={color}>
                    <Trans i18nKey="establishment.businessHours" />
                </CardTitle>
            </Grid>
            <Grid item xs={12}>
                {days.map((day, index) => (
                    <React.Fragment key={'days' + index}>
                        <GridHour
                            container
                            sx={{
                                backgroundColor: day.today ? '#FDF2EE' : 'none',
                            }}
                            itemProp="openingHours"
                            {...{ content: day.metaValue ? day.metaDay + ' ' + day.metaValue : '' }}
                        >
                            <Grid item sx={{ width: 80 }}>
                                <Typography
                                    sx={{
                                        fontWeight: day.today ? '600' : 'normal',
                                        color: day.today ? '#F38355' : '',
                                        paddingTop: 0.5,
                                        paddingBottom: 0.5,
                                    }}
                                >
                                    {day.label}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    flexGrow: 1,
                                    paddingLeft: 0.5,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: day.today ? '600' : 'normal',
                                        color: day.today ? '#F38355' : '',
                                        textAlign: 'right',
                                        paddingTop: 0.5,
                                        paddingBottom: 0.5,
                                        whiteSpace: 'pre-line',
                                    }}
                                >
                                    {day.value}
                                </Typography>
                            </Grid>
                        </GridHour>
                        <Divider
                            sx={{
                                borderColor: day.today || day.previousDay ? '#F38355' : 'rgba(0, 0, 0, 0.12)',
                                borderBottomWidth: day.today || day.previousDay ? 2 : 'thin',
                            }}
                        />
                    </React.Fragment>
                ))}
            </Grid>
        </Grid>
    )
}

export default BusinessHours
