import { createTheme, responsiveFontSizes } from '@mui/material'
import { EstablishmentModel } from '../models/establishmentModel'

export const createEstablishmentTheme = (establishment?: EstablishmentModel) => {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#000000',
            },
            secondary: {
                main: '#ffffff',
            },
            background: {
                default: establishment?.websiteBackgroundColorLight ?? '#f6f6f6',
                dark: establishment?.websiteBackgroundColorDark ?? '#000000',
            },
        },
        typography: {
            fontFamily: (establishment?.websiteFont && establishment?.websiteFont !== 'Open Sans'
                ? [establishment.websiteFont, 'Open Sans', 'sans-serif']
                : ['Open Sans', 'sans-serif']
            ).join(','),
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'initial',
                    },
                },
            },
            MuiCard: {
                defaultProps: {
                    elevation: 0,
                },
                styleOverrides: {
                    root: {
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: '#EBE6EF',
                        borderRadius: 0,
                    },
                },
            },
        },
    })
    return responsiveFontSizes(theme)
}
