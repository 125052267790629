import React from 'react'
import { Box, ButtonBase, CardMedia, IconButton, useMediaQuery, useTheme } from '@mui/material'
import moment from 'moment'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useImgix } from '../../core/services/imgix/useImgix'

interface Props {
    imageUrl: string
    createdAt: Date
    onZoom: (image: string) => void
    getImageSource: (picture?: string | null, dpr?: number) => { height: number; image: string }
    boxSize?: { width: number; height: number }
}

const ImageCard: React.FC<Props> = ({ imageUrl, createdAt, onZoom, getImageSource, boxSize }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })

    const source = getImageSource(imageUrl)
    const { getImage } = useImgix()
    const getRawImage = (picture?: string) => {
        return getImage(picture ?? process.env.GATSBY_ASSETS_URL + '/media/establishment/default.jpg')
    }

    return (
        <Box
            sx={{
                position: 'relative',
                maxWidth: 300,
                height: boxSize?.height,
                margin: 'auto',
            }}
        >
            <ButtonBase onClick={() => onZoom(imageUrl ?? '')} sx={{ width: '100%', height: '100%' }}>
                <CardMedia
                    itemProp="image"
                    {...{ content: getRawImage(imageUrl) }}
                    component="img"
                    src={source.image}
                    height={'100%'}
                    srcSet={
                        getImageSource(imageUrl, 1).image +
                        ' 1x, ' +
                        getImageSource(imageUrl, 2).image +
                        ' 2x, ' +
                        getImageSource(imageUrl, 3).image +
                        ' 3x'
                    }
                    alt={t('establishement.newsAlt', {
                        createdAt: moment(createdAt).format('D MMM'),
                    })}
                />
                <IconButton
                    component={'div'}
                    onClick={() => onZoom(imageUrl ?? '')}
                    sx={{
                        position: 'absolute',
                        bottom: 8,
                        right: 8,
                        bgcolor: 'primary.main',
                        borderColor: 'secondary.main',
                        borderWidth: 1,
                        borderStyle: 'solid',
                    }}
                    color="primary"
                    size={isMobileScreen ? 'medium' : 'large'}
                >
                    <ZoomOutMapIcon color="secondary" fontSize="inherit" />
                </IconButton>
            </ButtonBase>
        </Box>
    )
}

export default ImageCard
