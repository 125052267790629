import React from 'react'
import { Button, ButtonProps, CircularProgress } from '@mui/material'

export interface Props {
    loading?: boolean
}

export type SubmitButtonProps<C extends React.ElementType> = Props & ButtonProps<C, { component?: C }>

const SubmitButton: React.FC<SubmitButtonProps<React.ElementType>> = ({
    loading = false,
    disabled,
    children,
    ...rest
}) => {
    return (
        <Button
            startIcon={
                loading ? <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.26)' }} size={20} /> : undefined
            }
            disabled={disabled || loading}
            {...rest}
        >
            <span>{children}</span>
        </Button>
    )
}

export default SubmitButton
