import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { Avatar, Box, Card, CardContent, CardHeader, Typography } from '@mui/material'
import moment from 'moment/moment'
import React from 'react'

interface Props {
    reviewerName: string
    reviewerPicture: string
    reviewDate: Date
    rating?: number
    reviewText: string
    reviewImagesUrl?: string[]
}

const ReviewCard = React.forwardRef<HTMLDivElement, Props>(
    ({ reviewerName, reviewerPicture, reviewDate, rating, reviewText, reviewImagesUrl }) => {
        return (
            <Card sx={{ width: '100%', borderRadius: '16px' }}>
                <CardHeader
                    avatar={<Avatar src={reviewerPicture} />}
                    title={
                        <span itemProp="author" itemScope itemType="https://schema.org/Person">
                            <span itemProp="name">{reviewerName}</span>
                        </span>
                    }
                    subheader={
                        <span
                            itemProp="datePublished"
                            {...{ content: moment(reviewDate).format('YYYY-MM-DD') }}
                        >
                            {moment(reviewDate).format('DD MMMM yyyy')}
                        </span>
                    }
                />
                <CardContent sx={{ paddingTop: 0, maxHeight: '100%' }}>
                    {rating && (
                        <Box itemProp="reviewRating" itemScope itemType="https://schema.org/Rating">
                            <meta itemProp="worstRating" content={'1'} />
                            <meta itemProp="bestRating" content={'5'} />
                            <span itemProp="ratingValue" {...{ content: rating }}>
                                {[0, 1, 2, 3, 4].map((i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            {i < rating ? (
                                                <StarIcon sx={{ color: '#f9bc03' }} key={i} />
                                            ) : (
                                                <StarBorderIcon sx={{ color: '#f9bc03' }} key={i} />
                                            )}
                                        </React.Fragment>
                                    )
                                })}
                            </span>
                        </Box>
                    )}

                    <Typography
                        itemProp="reviewBody"
                        sx={{ fontSize: '0.9em', color: 'black', maxHeight: 250, overflow: 'auto' }}
                    >
                        {reviewText}
                    </Typography>
                </CardContent>
                {reviewImagesUrl?.map(() => {
                    return <></>
                })}
            </Card>
        )
    },
)

export default ReviewCard
