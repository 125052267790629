import { CardGiftcard } from '@mui/icons-material'
import { Backdrop, Box, Button, Drawer, Typography } from '@mui/material'
// @ts-ignore
import gamingBackground from 'assets/gaming-background2.png'
import { Trans } from 'gatsby-plugin-react-i18next'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import CloseButton from './components/closeButton'

interface Props {
    link?: string
}

const GamingDrawer: React.FC<Props> = ({ link }) => {
    const [open, setOpen] = useState(false)
    const [firstOpen, setFirstOpen] = useState(false)
    const drawerRef = useRef<HTMLDivElement>(null)
    const toggleDrawer = useCallback(
        (open: boolean) => {
            setFirstOpen(true)
            setOpen(open)
        },
        [setOpen, setFirstOpen],
    )

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!firstOpen) {
                toggleDrawer(true)
            }
        }, 5000)

        return () => {
            clearTimeout(timeout)
        }
    }, [toggleDrawer, firstOpen])

    if (!link || !/^(https?:\/\/)?([a-z0-9-]+[.])*onvaauresto\.fr(\/.*)?/i.test(link)) {
        return null
    }
    return (
        <>
            <Box
                sx={{
                    top: 0,
                    height: '100vh',
                    position: 'fixed',
                    left: open ? (drawerRef.current?.offsetWidth ?? 0) + 1 : 0,
                    zIndex: 1250,
                    transition: 'left 225ms cubic-bezier(0, 0, 0.2, 1)',
                }}
            >
                <Button
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        display: 'flex',
                        paddingLeft: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        paddingTop: 2,
                        paddingBottom: 2,
                    }}
                    variant="contained"
                    onClick={() => {
                        toggleDrawer(!open)
                    }}
                >
                    <Typography
                        variant="body1"
                        component="span"
                        sx={{
                            writingMode: 'vertical-lr',
                            textOrientation: 'mixed',
                            transform: 'rotate(180deg)',
                        }}
                    >
                        <Trans i18nKey="gaming.title" />
                    </Typography>
                    <CardGiftcard fontSize="medium" sx={{ marginTop: 1 }} />
                </Button>
            </Box>
            <Backdrop
                open={open}
                sx={{
                    zIndex: 1125,
                }}
                onClick={() => toggleDrawer(false)}
            />
            <Drawer
                variant="persistent"
                open={open}
                onClose={() => toggleDrawer(false)}
                PaperProps={{
                    sx: (theme) => ({
                        [theme.breakpoints.down('sm')]: {
                            top: '25vh',
                            height: '50vh',
                        },
                    }),
                }}
            >
                <Box
                    ref={drawerRef}
                    sx={{
                        bgcolor: '#e8e8e8',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        maxWidth: 'calc(100vw - 4rem)',
                    }}
                >
                    <Box
                        sx={{
                            paddingTop: 1,
                            display: 'flex',
                            height: '90%',
                            minWidth: 'min(100vh, 40vw)',
                        }}
                    >
                        <Box
                            sx={{
                                height: '100%',
                                width: '50%',
                                maxWidth: '36vh',
                                position: 'relative',
                            }}
                        >
                            <Box
                                component="img"
                                src={gamingBackground}
                                sx={(theme) => ({
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    right: '-30vh',
                                    height: '100%',
                                    aspectRatio: 'auto',
                                    [theme.breakpoints.down('sm')]: {
                                        right: '-12vh',
                                        height: '80%',
                                        top: '10%',
                                    },
                                })}
                            />
                        </Box>
                        <Box
                            sx={{
                                position: 'relative',
                                minWidth: '50%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingRight: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <CloseButton onClose={() => toggleDrawer(false)} />
                            </Box>
                            <Typography
                                sx={(theme) => ({
                                    color: '#7f7f7f',
                                    fontSize: '4vh',
                                    textAlign: 'center',
                                    fontWeight: '500',
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: '1.2rem',
                                    },
                                    [theme.breakpoints.down('xs')]: {
                                        fontSize: '1.4rem',
                                    },
                                })}
                            >
                                <Trans i18nKey="gaming.title" />
                            </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <Typography
                                sx={(theme) => ({
                                    color: '#0070c0',
                                    fontSize: '3vh',
                                    textAlign: 'center',
                                    fontWeight: '500',
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: '1.1rem',
                                    },
                                })}
                            >
                                <Trans i18nKey="gaming.hook" />
                            </Typography>
                            <Box sx={{ flexGrow: 4 }} />
                            <Button
                                href={link}
                                target="_blank"
                                variant="contained"
                                sx={(theme) => ({
                                    bgcolor: 'white',
                                    color: '#0070c0',
                                    fontSize: '4vh',
                                    textAlign: 'center',
                                    borderRadius: 12,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: '1.4rem',
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                    },
                                    [theme.breakpoints.down('xs')]: {
                                        fontSize: '1.2rem',
                                    },
                                })}
                            >
                                <Trans i18nKey="gaming.action" />
                            </Button>
                            <Box sx={{ flexGrow: 3 }} />
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </>
    )
}

export default GamingDrawer
