import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { DateValidationError, MuiTextFieldProps } from '@mui/x-date-pickers/internals'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Moment } from 'moment'
import React, { useState } from 'react'
import { Field } from 'react-final-form'

interface Props {
    name: string
    helperText?: React.ReactNode
    label?: MuiTextFieldProps['label']
    shouldDisableDate?: (day: Moment) => boolean
    margin?: 'dense' | 'normal' | 'none'
    minDate?: Moment
    required?: boolean
}

const DateField: React.FC<Props> = ({
    name,
    helperText,
    label,
    shouldDisableDate,
    minDate,
    margin = 'normal',
    required,
}) => {
    const { t } = useTranslation()
    const [error, setError] = useState<DateValidationError>()

    return (
        <Field name={name}>
            {({ input: { onChange, value }, meta }) => {
                const showError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <DatePicker
                        minDate={minDate}
                        label={label}
                        value={value}
                        onChange={onChange}
                        shouldDisableDate={shouldDisableDate}
                        onError={(reason) => setError(reason)}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                required={required}
                                margin={margin}
                                error={showError || params.error}
                                helperText={
                                    showError
                                        ? meta.error || meta.submitError
                                        : helperText ?? (error ? t('formValidation.' + error) : '')
                                }
                                {...params}
                            />
                        )}
                    />
                )
            }}
        </Field>
    )
}

export default DateField
