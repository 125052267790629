import { Container, Grid, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { useImgix } from 'core/services/imgix/useImgix'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import Section from './common/section'
import { Title } from './common/title'
import EventCard from './news/eventCard'
import NewsCard from './news/newsCard'

interface Props {
    establishment: EstablishmentModel
    color: 'primary' | 'secondary'
    onZoom: (image: string) => void
    canonicalUrl: string
}

export const NewsTitle = styled(Typography)(({ theme, color }) => ({
    color: color === 'primary' ? theme.palette.secondary.main : theme.palette.primary.main,
    fontSize: '0.9rem',
    textAlign: 'left',
    fontWeight: 600,
})) as typeof Typography

export const NewsDate = styled(Typography)(({ theme, color }) => ({
    color: color === 'primary' ? theme.palette.secondary.main : theme.palette.primary.main,
    fontSize: '0.9rem',
    textAlign: 'left',
})) as typeof Typography

const NewsSection = React.forwardRef<HTMLDivElement, Props>(
    (
        { establishment: { newsList, completeAddress, name, websiteSections }, color, onZoom, canonicalUrl },
        ref,
    ) => {
        const { t } = useTranslation()

        const id = 'news'
        const title = t('menu.news')
        const section = websiteSections?.find((section) => section.section === id)

        const theme = useTheme()
        const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true })
        const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })

        const { getImage } = useImgix()
        const columns = isMobileScreen ? 1 : 3
        const spacing = isMobileScreen ? 2 : 4
        const getImageSource = (picture?: string | null, dpr?: number) => {
            const image = picture ?? process.env.GATSBY_ASSETS_URL + '/media/establishment/default.jpg'
            let width = 1440
            let height = Math.round((width * 4) / 3)
            if (isLargeScreen) {
                width = 1536
                if (typeof window !== 'undefined' && document) {
                    height =
                        (window.innerHeight ||
                            document.documentElement.clientHeight ||
                            document.body.clientHeight) * 0.5
                }
            } else {
                if (typeof window !== 'undefined' && document) {
                    width =
                        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
                    height =
                        (window.innerHeight ||
                            document.documentElement.clientHeight ||
                            document.body.clientHeight) * 0.5
                }
            }
            const targetWidth = Math.round((width - 24 * 4 * columns) / columns)
            const targetHeight = Math.round(height)
            return {
                height: targetHeight,
                width: targetWidth,
                image: getImage(image, { w: targetWidth, h: targetHeight, fit: 'clip', dpr }),
            }
        }

        return (
            <Section
                ref={ref}
                id={id}
                title={title}
                color={color}
                backgroundImage={section?.backgroundImage ?? undefined}
                backgroundColor={section?.backgroundColor ?? undefined}
            >
                <Container maxWidth="xl">
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            zIndex: 3,
                        }}
                    >
                        <Grid item xs={12}>
                            <Title
                                component="h2"
                                color={color}
                                sx={{
                                    color: section?.textColor,
                                }}
                            >
                                {title}
                            </Title>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={spacing} justifyContent="center">
                                {newsList.map((news, index) => {
                                    return (
                                        <Grid key={'newsList' + index} item md={4} xs={12}>
                                            {news.event ? (
                                                <EventCard
                                                    // @ts-ignore
                                                    event={news}
                                                    getImageSource={getImageSource}
                                                    name={name}
                                                    canonicalUrl={canonicalUrl}
                                                    completeAddress={completeAddress}
                                                    onZoom={onZoom}
                                                    color={color}
                                                />
                                            ) : (
                                                <NewsCard
                                                    news={news}
                                                    getImageSource={getImageSource}
                                                    name={name}
                                                    canonicalUrl={canonicalUrl}
                                                    completeAddress={completeAddress}
                                                    onZoom={onZoom}
                                                    color={color}
                                                />
                                            )}
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Section>
        )
    },
)

export default NewsSection
