import RateReviewIcon from '@mui/icons-material/RateReview'
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { ReviewsModel } from 'core/models/reviewsModel'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { Autoplay, Keyboard, Lazy, Pagination } from 'swiper'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import ReviewCard from './common/reviewCard'
import Section from './common/section'
import { Title } from './common/title'
import Action from './order/action'

interface Props {
    establishment: EstablishmentModel
    reviews: ReviewsModel
    color: 'primary' | 'secondary'
}

const ReviewsSection = React.forwardRef<HTMLDivElement, Props>(
    ({ establishment: { googleReviewUri, googleUrl, websiteSections }, reviews, color }, ref) => {
        const { t } = useTranslation()

        const id = 'reviews'
        const title = t('menu.reviews')
        const section = websiteSections?.find((section) => section.section === id)

        const theme = useTheme()
        const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })
        const fontColor = color === 'primary' ? 'secondary' : 'primary'

        let averageRating = undefined
        if (reviews && reviews.averageRating) {
            let locale = 'fr-FR'
            if (typeof window !== 'undefined') {
                locale = navigator.language
            }
            const formatter = new Intl.NumberFormat(locale, { minimumFractionDigits: 1 })
            averageRating = formatter.format(reviews.averageRating)
        }

        return (
            <Section
                ref={ref}
                id={id}
                title={title}
                color={color}
                backgroundImage={section?.backgroundImage ?? undefined}
                backgroundColor={section?.backgroundColor ?? undefined}
            >
                <Container maxWidth="md">
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            zIndex: 3,
                        }}
                    >
                        <Grid item xs={12}>
                            <Title
                                component="h2"
                                color={color}
                                sx={{
                                    color: section?.textColor,
                                }}
                            >
                                {title}
                            </Title>
                        </Grid>
                        {reviews && reviews.reviews.length > 0 ? (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ textAlign: 'center' }}
                                    itemProp="aggregateRating"
                                    itemScope
                                    itemType="https://schema.org/AggregateRating"
                                >
                                    <Box
                                        sx={{
                                            display: 'inline-flex',
                                            alignItems: 'flex-end',
                                            columnGap: '0.2em',
                                        }}
                                    >
                                        <meta itemProp="worstRating" content={'1'} />
                                        <meta itemProp="bestRating" content={'5'} />
                                        <Typography
                                            fontSize={'2em'}
                                            color={fontColor}
                                            itemProp="ratingValue"
                                            sx={{
                                                color: section?.textColor,
                                            }}
                                        >
                                            {averageRating}
                                        </Typography>
                                        <Typography
                                            fontSize={'1.2em'}
                                            color={fontColor}
                                            sx={{
                                                color: section?.textColor,
                                            }}
                                        >
                                            {'/ 5'}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        color={fontColor}
                                        itemProp="reviewCount"
                                        sx={{
                                            color: section?.textColor,
                                        }}
                                        {...{ content: reviews.totalReviewCount }}
                                    >
                                        <Trans
                                            i18nKey={'establishment.reviewsCount'}
                                            values={{
                                                count: reviews.totalReviewCount,
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    itemProp="review"
                                    itemScope
                                    itemType="https://schema.org/Review"
                                >
                                    <Swiper
                                        onInit={(swiper) => {
                                            const pagination = swiper.params.pagination
                                            if (pagination) {
                                                swiper.pagination.init()
                                                swiper.pagination.render()
                                            }
                                        }}
                                        modules={[Lazy, Keyboard, Autoplay, Pagination]}
                                        centerInsufficientSlides
                                        slidesPerView={isMobileScreen ? 1 : 3}
                                        lazy
                                        keyboard
                                        autoplay={{ delay: 7000 }}
                                        pagination={{
                                            dynamicBullets: true,
                                            clickable: true,
                                        }}
                                        spaceBetween={24}
                                        style={{
                                            marginTop: theme.spacing(3),
                                            marginBottom: theme.spacing(3),
                                            paddingBottom: theme.spacing(3),
                                        }}
                                    >
                                        {reviews.reviews.map((review, index) => {
                                            return (
                                                <SwiperSlide
                                                    key={'review' + index}
                                                    style={{
                                                        justifyContent: 'center',
                                                        width: 'inherit',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <>
                                                        <ReviewCard
                                                            reviewerName={review.authorName}
                                                            reviewerPicture={review.authorImg}
                                                            reviewDate={review.createdAt}
                                                            reviewText={review.text}
                                                            rating={review.rating}
                                                        />
                                                    </>
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <Typography
                                    color={fontColor}
                                    sx={
                                        section?.textColor
                                            ? {
                                                  color: section?.textColor,
                                              }
                                            : {}
                                    }
                                    variant={'h5'}
                                    style={{ marginBottom: theme.spacing(3) }}
                                >
                                    <Trans i18nKey={'reviews.emptyReview'} />
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Action
                                color={color}
                                href={googleReviewUri || googleUrl || ''}
                                Icon={RateReviewIcon}
                                text={<Trans i18nKey="actions.giveReview" />}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Section>
        )
    },
)

export default ReviewsSection
